<div class="container container-top-margin add-container">
  <div class="row">
    <div class="col">
      <div class="page-head">
        <div class="flex flex-centerd">
          <i class="uil uil-book-medical"></i>
          <div class="flex-column">
            <h4 class="h4-responsive text-center">
              {{ id ? ("editFaq" | translate) : ("addFaq" | translate) }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-center">
    <div class="col">
      <mat-card class="add-card">
        <form [formGroup]="form">
          <div class="progress form-group">
            <div
              class="progress-bar progress-bar-striped bg-success-bar"
              role="progressbar"
              [style.width.%]="progressV"
            ></div>
          </div>
          <div class="row text-center">
            <div class="col">
              <h5 color="primary">
                <span>{{ "video" | translate }}</span>
              </h5>
              <label *ngIf="thumbnailFileListV.length > 0" class="upload-label" for="upload-photo" appDrag>
                <video controls width="640px" height="267px" controls>
                <!-- <video controls width="250"> -->
                  <!-- <source [src]="thumbnailUrlListV | secure | async" type="video/webm" /> -->
                  <!-- <source [src]="thumbnailUrlListV | secure | async" type="video/mp4" /> -->
                  <source src="{{thumbnailUrlListV}}" type="video/mp4"  />
                  <source src="{{thumbnailUrlListV}}" type="video/webm"  />
                    <!-- Download the<a [href]="thumbnailUrlList">WEBM</a>or<a [href]="thumbnailUrlList">MP4</a>video. -->
                </video>
                <i class="material-icons pointer close-icon" (click)="deleteUploadedVideo()"> close </i>
                <!-- <i fileSaver [method]="'GET'" [fileName]="'videoName.mp4'" [url]="'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4'" class="material-icons pointer download-icon" style="top:50%">download</i> -->
                <!-- <button class="downlaod-video-button" type="button" fileSaver [method]="'GET'" [fileName]="'videoName.mp4'" [url]="thumbnailUrlListV">Download Video</button> -->
              </label>
              <label *ngIf="thumbnailFileListV.length === 0" class="upload-label" for="upload-thumbnail-mp4" appDrag >
                <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                <input type="file" class="upload-thumbnail-id" accept="video/mp4,video/x-m4v,video/*" (change)="videoSelectFile($event)" name="my-video" id="upload-thumbnail-mp4" />
              </label>
              <br />
              <br />
            </div>
          </div>
          <div class="progress form-group">
            <div
              class="progress-bar progress-bar-striped bg-success-bar"
              role="progressbar"
              [style.width.%]="progress"
            ></div>
          </div>
          <div class="row text-center">
            <div class="col">
              <h5 color="primary">
                <span>{{ "image" | translate }}</span>
              </h5>
              <label *ngIf="thumbnailFileList.length > 0" class="upload-label" for="upload-photo" appDrag >
                <!-- <img [src]="thumbnailUrlList" width="80%" alt="" class="pointer" /> -->
                <img [src]="thumbnailUrlList | secure | async" width="80%" alt="" class="pointer" />
                <i class="material-icons pointer close-icon" (click)="deleteUploadedThumbnailImage()"> close</i>
              </label>
              <label *ngIf="thumbnailFileList.length === 0" class="upload-label" for="upload-thumbnail" appDrag >
                <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                <input type="file" accept="image/*" (change)="thumbnailSelectFile($event)" name="photo" id="upload-thumbnail" />
              </label>
              <br />
              <br />
            </div>
          </div>
          <mat-tab-group>
            <mat-tab>
              <ng-template mat-tab-label>
                {{ "arabic" | translate }}
              </ng-template>
              <div class="row text-center" style="margin-top: 1rem">
                <div class="col-12">
                  <!-- <div class="example-container"> -->
                    <mat-form-field class="full-width">
                      <span>{{ "question" | translate }}</span>
                      <input matInput formControlName="questionAr" />
                      <mat-hint></mat-hint>
                      <mat-error *ngIf="valid('questionAr', 'required')">{{
                        "required" | translate
                      }}</mat-error>
                    </mat-form-field>
                  <!-- </div> -->
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span>{{ "answer" | translate }}</span>
                  <ckeditor
                    [editor]="Editor"
                    formControlName="answerAr"
                  ></ckeditor>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                {{ "english" | translate }}
              </ng-template>
              <div class="row text-center" style="margin-top: 1rem">
                <div class="col-12">
                  <!-- <div class="example-container"> -->
                    <mat-form-field class="full-width">
                      <span>{{ "question" | translate }}</span>
                      <input matInput formControlName="questionEn" />
                      <mat-hint></mat-hint>
                      <mat-error *ngIf="valid('questionEn', 'required')">{{
                        "required" | translate
                      }}</mat-error>
                    </mat-form-field>
                  <!-- </div> -->
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span>{{ "answer" | translate }}</span>
                  <!-- <ckeditor
                    formControlName="answerEn"
                    [config]="editorConfig"
                  ></ckeditor> -->
                  <ckeditor
                  [editor]="Editor"
                  formControlName="answerEn"
                ></ckeditor>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </form>
        <div class="row" style="margin-top: 1rem">
          <div class="col">
            <div class="row text-center">
              <div class="col">
                <button
                  mat-raised-button
                  color="warn"
                  (click)="addOrEdit()"
                  [disabled]="!form.valid"
                >
                  {{ id ? ("editFaq" | translate) : ("addFaq" | translate) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
