<div class="container container-top-margin add-container">
    <div class="row">
      <div class="col">
        <div class="page-head">
          <div class="flex flex-centerd">
            <i class="uil uil-book-medical"></i>
            <div class="flex-column">
              <h4 class="h4-responsive text-center" *ngIf="!objectId">
                {{ "addNewBriefLesson" | translate }}
              </h4>
              <h4 class="h4-responsive text-center" *ngIf="objectId">
                {{ "editBriefLesson" | translate }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!(spinner$ | async)">
      <div class="col">
        <mat-stepper orientation="vertical" [linear]="true" #stepper>
          <mat-step [stepControl]="firstFormGroup">
            <form [formGroup]="firstFormGroup">
              <ng-template matStepLabel>{{'selectMaterial' | translate}}</ng-template>
                <mat-form-field class="full-width">
                  <mat-label>{{ "lessonsCat" | translate }}</mat-label>
                  <mat-select placeholder="{{ '' | translate }}" formControlName="lessonsCat" >
                    <mat-option *ngFor="let g of lessonsCategories" [value]="g._id">
                      {{ g.nameEn }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              <div>
                <button mat-button matStepperNext (click)="getUnitsForLessonsCat()">{{'next' | translate}}</button>
              </div>
            </form>
          </mat-step>
          <mat-step [stepControl]="form">
            <mat-card class="add-card">
              <form [formGroup]="form">
                <div class="progress form-group">
                  <div
                    class="progress-bar progress-bar-striped bg-success-bar"
                    role="progressbar"
                    [style.width.%]="progressV"
                  ></div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <h5 color="primary">
                      <span>{{ "video" | translate }}</span>
                    </h5>
                    <label *ngIf="thumbnailFileListV.length > 0" class="upload-label" for="upload-photo" appDrag>
                      <video controls width="640px" height="267px" controls>
                        <source src="{{thumbnailUrlListV}}" type="video/mp4"  />
                        <source src="{{thumbnailUrlListV}}" type="video/webm"  />
                      </video>
                      <i class="material-icons pointer close-icon" (click)="deleteUploadedVideo()"> close </i>
                    </label>
                    <label *ngIf="thumbnailFileListV.length === 0" class="upload-label" for="upload-thumbnail-mp4" appDrag >
                      <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                      <input type="file" class="upload-thumbnail-id" accept="video/mp4,video/x-m4v,video/*" (change)="videoSelectFile($event)" name="my-video" id="upload-thumbnail-mp4" />
                    </label>
                    <br />
                    <br />
                  </div>
                </div>
                <div class="progress form-group">
                  <div
                    class="progress-bar progress-bar-striped bg-success-bar"
                    role="progressbar"
                    [style.width.%]="progress"
                  ></div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <h5 color="primary">
                      <span>{{ "image" | translate }}</span>
                    </h5>
                    <label *ngIf="thumbnailFileList.length > 0" class="upload-label" for="upload-photo" appDrag >
                      <img [src]="thumbnailUrlList | secure | async" width="80%" alt="" class="pointer" />
                      <i class="material-icons pointer close-icon" (click)="deleteUploadedThumbnailImage()"> close</i>
                    </label>
                    <label *ngIf="thumbnailFileList.length === 0" class="upload-label" for="upload-thumbnail" appDrag >
                      <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                      <input type="file" accept="image/*" (change)="thumbnailSelectFile($event)" name="photo" id="upload-thumbnail" />
                    </label>
                    <br />
                    <br />
                  </div>
                </div>
                <div class="progress form-group">
                  <div
                    class="progress-bar progress-bar-striped bg-success-bar"
                    role="progressbar"
                    [style.width.%]="progressF"
                  ></div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <h5 color="primary">
                      <span>{{ "file" | translate }}</span>
                    </h5>
                    <label *ngIf="fileFileList.length > 0" class="upload-label" for="upload-file" appDrag >
                      <p>Open a PDF file<a [href]="fileUrlList" target="_blank" style="margin: 0 20px" >{{'--here--' | translate}}</a>.
                      </p>
                      <br />
                      <i style="position: relative; margin: 0 20px" class="material-icons pointer close-icon" (click)="deleteUploadedFileFiles(thumbnailUrlList)" >close</i >
                    </label>
                    <label *ngIf="fileFileList.length === 0" class="upload-label" for="upload-fileth" appDrag >
                      <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                      <input type="file" accept="application/pdf" (change)="filefilesSelectFile($event)" name="file" id="upload-fileth" />
                    </label>
                    <br />
                    <br />
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-6 margin-auto">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="nameEn"
                        matInput
                        placeholder="{{ 'nameEn' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-6 margin-auto">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="nameFr"
                        matInput
                        placeholder="{{ 'nameFr' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-6 margin-auto">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="nameAr"
                        matInput
                        placeholder="{{ 'nameAr' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                  <div class="col-6">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="index"
                        matInput
                        placeholder="{{ 'index' | translate }}"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col">
                    <mat-form-field class="full-width">
                      <mat-label>{{ "lessonUnit" | translate }}</mat-label>
                      <mat-select
                        placeholder="{{ '' | translate }}"
                        formControlName="unit"
                      >
                        <mat-option *ngFor="let g of units" [value]="g._id">
                          {{ g.nameEn }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
        
                <div class="row text-center">
                  <div class="col">
                    <br />
                    <br />
                    <br />
                    <button
                      mat-raised-button
                      (click)="addEdit()"
                      [disabled]="!form.valid"
                      color="primary"
                    >
                      <span *ngIf="!objectId">{{ "addNewBriefLesson" | translate }}</span>
                      <span *ngIf="objectId">{{ "editBriefLesson" | translate }}</span>
                    </button>
                  </div>
                </div>
              </form>
            </mat-card>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </div>
  