import { ImageComponent } from './../../modals/image/image.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/user.service';
import { FullServiceService } from '../../../services/full-service.service';
import { User, UserModel } from '../../../models/user';
import { SendSpecificNotiComponent } from '../../send-specific-noti/send-specific-noti.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';
import { AddPackageToUserComponent } from '../add-package-to-user/add-package-to-user.component';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';

import { TableUtil } from "../tableUtil";
import { branches } from '../../../models/BaseURL';
import { ChangeBranchModalComponent } from '../../modals/change-branch-modal/change-branch-modal.component';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  pageEvent;
  activeForm: UntypedFormGroup;
  verifiedForm: UntypedFormGroup;
  cityForm: UntypedFormGroup;
  promotedForm: UntypedFormGroup;
  data: UserModel[] = [];
  searchWord = '';

  cities = [];
  states = [];

  userFilter: UntypedFormGroup;

  displayedColumns: string[] = [
    'name',
    'branch',
    'email',
    'phone',
    'whatsappPhone',
    'active',
    'date',
    // 'verified',
    'city',
    'package',
    'pausePackage',
    'actions',
    // 'addPackage',
    // 'edit',//
    // 'changePassword',
    // 'delete',
  ];
  dataSource: MatTableDataSource<UserModel>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 20;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;
  // currentLang = '';
  minDate: Date;
  packages =[];
  branches =[];

  isLoadingData$: Observable<boolean>;


  searchWordUpdate = new Subject<string>();
  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrManager,
    public dialog: MatDialog,
    private full: FullServiceService,
    )
    {
      this.isLoadingData$ = full.isLoadingDataSubject.asObservable();
      const userData = JSON.parse(localStorage.getItem("currentUser")).data.user;
      const currentDate= new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDay();
      userData.role === "employee" ? this.minDate = new Date(currentYear, currentMonth - 3, 31 - currentDay) : this.minDate = new Date(currentYear - 200, 0, 1);
      this.searchWordUpdate.pipe(
        debounceTime(600),
        distinctUntilChanged())
        .subscribe(value => {
          this.getAllUsers(1);
      });
    }

  ngOnInit() {
    this.userFilter = new UntypedFormGroup({
      active: new UntypedFormControl(null, []),
      verified: new UntypedFormControl(null, []),
      city: new UntypedFormControl(null, []),
      package: new UntypedFormControl(null, []),
      branch: new UntypedFormControl(null, []),
      fromDate: new UntypedFormControl(null, []),
      toDate: new UntypedFormControl(null, []),
    })

    this.activeForm = new UntypedFormGroup({
      active: new UntypedFormControl('', [])
    });
    this.verifiedForm = new UntypedFormGroup({
      verified: new UntypedFormControl('', [])
    });
    this.cityForm = new UntypedFormGroup({
      city: new UntypedFormControl('', [])
    });

    this.getAllUsers(1);
    // this.full.getAllParentCities().subscribe((data: any) => {
    this.full.getAllCitiesForDropdown().subscribe((data: any) => {
      this.cities = data.data;
    })
    this.full.getAllPackagesForDropdown(0, 20, '', true, false).subscribe((data: any) => {
      this.packages = data.data;
    })
    this.branches = branches
  }
  get fromDate() { return this.userFilter.get('fromDate').value; }
  get toDate() { return this.userFilter.get('toDate').value; }
  getAll() {
    this.userFilter.patchValue({
      active: null,
      verified: null,
      city: null,
    })
    this.getAllUsers(1);
  }

  edit(id) {
    console.log('id', id);
    
    // let url = window.location.href.replace('users-list', `edit-user/${id}`);
    // window.open(url, '_blank')
  }

  getAllUsers(event: any) {
    this.full.isLoadingDataSubject.next(true);
    this.userService.getAllUsersForAdmin(event.pageIndex + 1 || 1, event.pageSize, this.searchWord, this.userFilter.value).subscribe((data: any) => {
      this.data = data.data;
      this.dataSource = new MatTableDataSource(this.data);
      // this.length = data.itemCount;
      this.length = data.paging.total;
      this.pageSize = data.paging.pageSize;
      this.full.isLoadingDataSubject.next(false);
    }, err => {
    });
  }

  filter() {
    this.getAllUsers(1);
  }

  searchUsers() {
    this.getAllUsers(1);
  }
  getStatesForCity(id) {
    this.full.getAllSubsForCity(1, 1000, id).subscribe((data: any) => {
      this.states = data.data;
    })
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openImage(image: string): void {
    const dialogRef = this.dialog.open(ImageComponent, {
      width: '650px',
      data: { image: image[0] }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.image = result;
    });
  }

  notiModal(id: string): void {
    const dialogRef = this.dialog.open(SendSpecificNotiComponent, {
      width: '750px',
      data: id
    });

    dialogRef.afterClosed().subscribe(result => {
      this.image = result;
    });
  }

  activate(user: User, event: MatSlideToggleChange) {
    this.activeForm.patchValue({
      active: event.checked
    });

    this.userService.changeStatusUser(user._id, this.activeForm.value).subscribe((data: any) => {
      user.active = event.checked;
      this.full.openSnackBar(this.translate.instant('statusChanged'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }
  changePausePackage(user: any, event: MatSlideToggleChange) {
    // console.log('user', user);
    const userId= user._id;
    const packageId = user?.package._id
    if(!userId || !packageId) return;
    this.userService.changeUserPackagePause(userId,packageId).subscribe((data: any) => {
      this.full.openSnackBar(this.translate.instant('statusChanged'), 'أغلق', 'success');
      this.getAllUsers(1)
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  changePromoted(article: any, event: MatSlideToggleChange) {
    this.promotedForm.patchValue({
      promoted: event.checked
    });

    this.userService.changeStatusUser(article._id, this.promotedForm.value).subscribe((data: any) => {
      article.promoted = event.checked;
      this.full.openSnackBar(this.translate.instant('statusChanged'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  changeVerified(article: any, event: MatSlideToggleChange) {
    this.verifiedForm.patchValue({
      verified: event.checked
    });

    this.userService.changeStatusUser(article._id, this.verifiedForm.value).subscribe((data: any) => {
      article.verified = event.checked;
      this.full.openSnackBar(this.translate.instant('statusChanged'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  filterGallery(id: string) {
    this.router.navigate(['/gallery', { user: id }]);
  }

  confirmDialog(id): void {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  delete(id) {
    this.full.deleteOneUser(id).subscribe((data: any) => {
      console.log('d', data);
      if(data.status){
        this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
        this.full.openSnackBar(this.translate.instant('userDeletedSuccess'), 'أغلق', 'success');
      }else{
        this.full.openSnackBar(data.message, 'أغلق', 'danger');  
      }
    }, err => {
      this.full.openSnackBar(this.translate.instant('errorWhenDelet'), 'أغلق', 'danger');
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }

  clearFilter() {
    this.searchWord = '';
    this.userFilter.get('city').setValue(null);
    this.userFilter.get('active').setValue(null);
    this.userFilter.get('verified').setValue(null);
    this.userFilter.get('package').setValue(null);
    this.userFilter.get('branch').setValue(null);
    this.getAllUsers(1);
  }
  openAddPackageModal(id: string): void {
    const dialogRef = this.dialog.open(AddPackageToUserComponent, {
      data: id
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result && result === true){
        this.getAllUsers(1);
      }
    });
  }

  chagngePasswordDialog(id: string){
    console.log('cchagngePasswordDialog', id);
    const dialogRef = this.dialog.open(ChangePasswordModalComponent, {
      width: '50vw',
      data: { userId: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('resuslts', result);
      
    });
  }
  exportArray() {
    const onlyNameAndSymbolArr = this.dataSource.data.map(x => ({
      name: x.name,
      phone: x.phone,
      whatsapp: x.whatsappPhone,
      email: x.email,
    }));
    TableUtil.exportArrayToExcel(onlyNameAndSymbolArr, "ExampleArray");
  }

  changeUserBranch2(id, branch) {
    console.log('asd', id);
    const dialogRef = this.dialog.open(ChangeBranchModalComponent, {
      data: {id: id, model: 'users', branch: branch}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result && result === true){
        this.getAllUsers(1);
      }
    });
  }
  changeUserBranch(id, branch) {
    console.log(id, branch);
    const branchTo = branch === 'scientific' ? 'literary' : 'scientific';
    const message = branch === 'scientific' ? this.translate.instant('changeToL') : this.translate.instant('changeToS');

    const dialogData = new ConfirmDialogModel(this.translate.instant('yes'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.changeBranch(id, {branch: branchTo});
      } else {
        this.full.openSnackBar('تم إلغاء العملية', 'أغلق', 'danger');
      }
    });
  }
  changeBranch(id, body) {
    this.full.changeUserBranch(id, body).subscribe((data: any) => {
      console.log('d', data);
      if(data.status){
        this.getAllUsers(1);
        this.full.openSnackBar(this.translate.instant('changeBranchSuccess'), 'أغلق', 'success');
      }else{
        this.full.openSnackBar(data.message, 'أغلق', 'danger');  
      }
    }, err => {
      this.full.openSnackBar(this.translate.instant('error'), 'أغلق', 'danger');
    });
  }
}