<div class="container container-top-margin add-container">
  <div class="row">
    <div class="col">
      <div class="page-head">
        <div class="flex flex-centerd">
          <i class="uil uil-book-medical"></i>
          <div class="flex-column">
            <h4 class="h4-responsive text-center" *ngIf="!objectId">
              {{ "addNewQuestionExam" | translate }}
            </h4>
            <h4 class="h4-responsive text-center" *ngIf="objectId">
              {{ "editQuestionExam" | translate }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-card class="add-card">
        <form [formGroup]="form">
          <div class="row text-center">
            <div class="col-12 margin-auto">
              <div class="progress form-group">
                <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar" [style.width.%]="progressQI"></div>
              </div>
              <div class="row text-center">
                <div class="col">
                  <h5 color="primary">
                    <span>{{ "questionImage" | translate }}</span>
                  </h5>
                  <label *ngIf="qIFileList.length > 0" class="upload-label" for="questionImageL" appDrag>
                    <img [src]="qIUrlList | secure | async" width="80%" alt="" class="pointer"/>
                    <i class="material-icons pointer close-icon" (click)="deleteUploadedQImage()">close</i>
                  </label>
                  <label *ngIf="qIFileList.length === 0" class="upload-label" for="questionImageT" appDrag>
                    <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                    <input class="upload-thumbnail-id" type="file" (change)="qISelectFile($event)" accept="image/*" name="photo" id="questionImageT"/>
                  </label>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-12 margin-auto">
              <mat-form-field class="full-width">
                <input
                  formControlName="questionAr"
                  matInput
                  placeholder="{{ 'questionAr' | translate }}"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-12 margin-auto">
              <mat-form-field class="full-width">
                <input
                  formControlName="questionEn"
                  matInput
                  placeholder="{{ 'questionEn' | translate }}"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-12 margin-auto">
              <mat-form-field class="full-width">
                <input
                  formControlName="questionFr"
                  matInput
                  placeholder="{{ 'questionFr' | translate }}"
                />
              </mat-form-field>
            </div>
          </div>
          <!-- <div class="row text-center"> -->
          <mat-tab-group
            mat-stretch-tabs="false"
            mat-align-tabs="center"
            style="margin-bottom: 10px; display: none;"
          >
            <mat-tab label="{{ 'answer1' | translate }}">
              <div class="row text-center" style="margin: 20px 0">
                <div class="col col-12">
                  <mat-form-field class="full-width">
                    <input
                      formControlName="answer1En"
                      matInput
                      placeholder="{{ 'answer1' | translate }}"
                    />
                  </mat-form-field>
                </div>
                <!-- <div class="col col-12">
                                        <mat-form-field class="full-width">
                                            <input formControlName='answer1Fr' matInput placeholder="{{'answer1' | translate}}">
                                        </mat-form-field>
                                    </div>
                                    <div class="col col-12">
                                        <mat-form-field class="full-width">
                                            <input formControlName='answer1Ar' matInput placeholder="{{'answer1' | translate}}">
                                        </mat-form-field>
                                    </div> -->
                <div class="col col-12">
                  <div class="mat-mdc-form-field full-width">
                    <div class="mdc-text-field mat-mdc-text-field-wrapper">
                      <div class="mat-mdc-form-field-focus-overlay"></div>
                      <div class="mat-mdc-form-field-flex">
                        <mat-checkbox
                          id="answer1isTrue"
                          class="example-margin"
                          formControlName="answer1isTrue"
                          >{{ "isTrueAnswer" | translate }}</mat-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </div>
                  <div class="col-12 margin-auto">
                    <div class="progress form-group">
                      <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar" [style.width.%]="progressA1"></div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <h5 color="primary">
                          <span>{{ "answer1Image" | translate }}</span>
                        </h5>
                        <label *ngIf="a1FileList.length > 0" class="upload-label" for="answer1ImageL" appDrag>
                          <img [src]="a1UrlList | secure | async" width="80%" alt="" class="pointer"/>
                          <i class="material-icons pointer close-icon" (click)="deleteUploadedAImage(1)">close</i>
                        </label>
                        <label *ngIf="a1FileList.length === 0" class="upload-label" for="answer1ImageT" appDrag>
                          <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                          <input class="upload-thumbnail-id" type="file" (change)="aSelectFile($event, 1)" accept="image/*" name="photo" id="answer1ImageT"/>
                        </label>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
              </div>
            </mat-tab>
            <mat-tab label="{{ 'answer2' | translate }}">
              <div class="row text-center" style="margin: 20px 0">
                <div class="col col-12">
                  <mat-form-field class="full-width">
                    <input
                      formControlName="answer2En"
                      matInput
                      placeholder="{{ 'answer2' | translate }}"
                    />
                  </mat-form-field>
                </div>
                <!-- <div class="col col-12">
                                        <mat-form-field class="full-width">
                                            <input formControlName='answer2Fr' matInput placeholder="{{'answer2' | translate}}">
                                        </mat-form-field>
                                    </div>
                                    <div class="col col-12">
                                        <mat-form-field class="full-width">
                                            <input formControlName='answer2Ar' matInput placeholder="{{'answer2' | translate}}">
                                        </mat-form-field>
                                    </div> -->
                <div class="col col-12">
                  <div class="mat-mdc-form-field full-width">
                    <div class="mdc-text-field mat-mdc-text-field-wrapper">
                      <div class="mat-mdc-form-field-focus-overlay"></div>
                      <div class="mat-mdc-form-field-flex">
                        <mat-checkbox
                          id="answer2isTrue"
                          class="example-margin"
                          formControlName="answer2isTrue"
                          >{{ "isTrueAnswer" | translate }}</mat-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 margin-auto">
                  <div class="progress form-group">
                    <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar" [style.width.%]="progressA2"></div>
                  </div>
                  <div class="row text-center">
                    <div class="col">
                      <h5 color="primary">
                        <span>{{ "answer2Image" | translate }}</span>
                      </h5>
                      <label *ngIf="a2FileList.length > 0" class="upload-label" for="answer2ImageL" appDrag>
                        <img [src]="a2UrlList | secure | async" width="80%" alt="" class="pointer"/>
                        <i class="material-icons pointer close-icon" (click)="deleteUploadedAImage(2)">close</i>
                      </label>
                      <label *ngIf="a2FileList.length === 0" class="upload-label" for="answer2ImageT" appDrag>
                        <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                        <input class="upload-thumbnail-id" type="file" (change)="aSelectFile($event, 2)" accept="image/*" name="photo" id="answer2ImageT"/>
                      </label>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="{{ 'answer3' | translate }}">
              <div class="row text-center" style="margin: 20px 0">
                <div class="col col-12">
                  <mat-form-field class="full-width">
                    <input
                      formControlName="answer3En"
                      matInput
                      placeholder="{{ 'answer3' | translate }}"
                    />
                  </mat-form-field>
                </div>
                <!-- <div class="col col-12">
                                        <mat-form-field class="full-width">
                                            <input formControlName='answer3Fr' matInput placeholder="{{'answer3' | translate}}">
                                        </mat-form-field>
                                    </div>
                                    <div class="col col-12">
                                        <mat-form-field class="full-width">
                                            <input formControlName='answer3Ar' matInput placeholder="{{'answer3' | translate}}">
                                        </mat-form-field>
                                    </div> -->
                <div class="col col-12">
                  <div class="mat-mdc-form-field full-width">
                    <div class="mdc-text-field mat-mdc-text-field-wrapper">
                      <div class="mat-mdc-form-field-focus-overlay"></div>
                      <div class="mat-mdc-form-field-flex">
                        <mat-checkbox
                          id="answer3isTrue"
                          class="example-margin"
                          formControlName="answer3isTrue"
                          >{{ "isTrueAnswer" | translate }}</mat-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </div>
                  <div class="col-12 margin-auto">
                    <div class="progress form-group">
                      <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar" [style.width.%]="progressA3"></div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <h5 color="primary">
                          <span>{{ "answer3Image" | translate }}</span>
                        </h5>
                        <label *ngIf="a3FileList.length > 0" class="upload-label" for="answer3ImageL" appDrag>
                          <img [src]="a3UrlList | secure | async" width="80%" alt="" class="pointer"/>
                          <i class="material-icons pointer close-icon" (click)="deleteUploadedAImage(3)">close</i>
                        </label>
                        <label *ngIf="a3FileList.length === 0" class="upload-label" for="answer3ImageT" appDrag>
                          <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                          <input class="upload-thumbnail-id" type="file" (change)="aSelectFile($event, 3)" accept="image/*" name="photo" id="answer3ImageT"/>
                        </label>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
              </div>
            </mat-tab>
            <mat-tab label="{{ 'answer4' | translate }}">
              <div class="row text-center" style="margin: 20px 0">
                <div class="col col-12">
                  <mat-form-field class="full-width">
                    <input
                      formControlName="answer4En"
                      matInput
                      placeholder="{{ 'answer4' | translate }}"
                    />
                  </mat-form-field>
                </div>
                <!-- <div class="col col-12">
                                        <mat-form-field class="full-width">
                                            <input formControlName='answer4Fr' matInput placeholder="{{'answer4' | translate}}">
                                        </mat-form-field>
                                    </div>
                                    <div class="col col-12">
                                        <mat-form-field class="full-width">
                                            <input formControlName='answer4Ar' matInput placeholder="{{'answer4' | translate}}">
                                        </mat-form-field>
                                    </div> -->
                <div class="col col-12">
                  <div class="mat-mdc-form-field full-width">
                    <div class="mdc-text-field mat-mdc-text-field-wrapper">
                      <div class="mat-mdc-form-field-focus-overlay"></div>
                      <div class="mat-mdc-form-field-flex">
                        <mat-checkbox
                          id="answer4isTrue"
                          class="example-margin"
                          formControlName="answer4isTrue"
                          >{{ "isTrueAnswer" | translate }}</mat-checkbox
                        >
                      </div>
                    </div>
                  </div>
                </div>
                  <div class="col-12 margin-auto">
                    <div class="progress form-group">
                      <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar" [style.width.%]="progressA4"></div>
                    </div>
                    <div class="row text-center">
                      <div class="col">
                        <h5 color="primary">
                          <span>{{ "answer4Image" | translate }}</span>
                        </h5>
                        <label *ngIf="a4FileList.length > 0" class="upload-label" for="answer4Image" appDrag>
                          <img [src]="a4UrlList | secure | async" width="80%" alt="" class="pointer"/>
                          <i class="material-icons pointer close-icon" (click)="deleteUploadedAImage(4)">close</i>
                        </label>
                        <label *ngIf="a4FileList.length === 0" class="upload-label" for="answer4ImageT" appDrag>
                          <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                          <input class="upload-thumbnail-id" type="file" (change)="aSelectFile($event, 4)" accept="image/*" name="photo" id="answer4ImageT"/>
                        </label>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
              </div>
            </mat-tab>
          </mat-tab-group>
          <!-- </div> -->

          
          <div class="progress form-group">
            <div
              class="progress-bar progress-bar-striped bg-success-bar"
              role="progressbar"
              [style.width.%]="progressV"
            ></div>
          </div>
          <div class="row text-center">
            <div class="col">
              <h5 color="primary">
                <span>{{ "video" | translate }}</span>
              </h5>
              <label *ngIf="thumbnailFileListV.length > 0" class="upload-label" for="upload-photo" appDrag>
                <video controls width="640px" height="267px" controls>
                  <source src="{{thumbnailUrlListV}}" type="video/mp4"  />
                  <source src="{{thumbnailUrlListV}}" type="video/webm"  />
                </video>
                <i class="material-icons pointer close-icon" (click)="deleteUploadedVideo()"> close </i>
              </label>
              <label *ngIf="thumbnailFileListV.length === 0" class="upload-label" for="upload-thumbnail-mp4" appDrag >
                <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                <input type="file" class="upload-thumbnail-id" accept="video/mp4,video/x-m4v,video/*" (change)="videoSelectFile($event)" name="my-video" id="upload-thumbnail-mp4" />
              </label>
              <br />
              <br />
            </div>
          </div>
          <div class="row text-center">
            <div class="col">
              <div class="progress form-group">
                <div
                  class="progress-bar progress-bar-striped bg-success-bar"
                  role="progressbar"
                  [style.width.%]="progressVideoImage"
                ></div>
              </div>
              <div class="row text-center">
                <div class="col">
                  <h5 color="primary">
                    <span>{{ "video_Image" | translate }}</span>
                  </h5>
                  <label *ngIf="videoImageFileList.length > 0" class="upload-label" for="upload-photo-2" appDrag >
                    <img [src]="videoImageUrlList | secure | async" width="80%" alt="" class="pointer" />
                    <i class="material-icons pointer close-icon" (click)="deleteUploadedVideoImage()"> close</i>
                  </label>
                  <label *ngIf="videoImageFileList.length === 0" class="upload-label" for="upload-thumbnail-3" appDrag >
                    <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                    <input type="file" accept="image/*" (change)="videoImageSelectFile($event)" name="photo" id="upload-thumbnail-3" />
                  </label>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-12">
              <span>{{ "fullAnswer" | translate }}</span>
              <!-- <ckeditor
                formControlName="fullAnswer"
                [config]="editorConfig"
                (ready)="onReady($event)"
                rows="5"
              ></ckeditor> -->
              <ckeditor [editor]="Editor" formControlName="fullAnswer" ></ckeditor>
            </div>
          </div>
          <div class="row text-center">
            <div class="col-12 margin-auto">
              <div class="progress form-group">
                <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar" [style.width.%]="progressFAI"></div>
              </div>
              <div class="row text-center">
                <div class="col">
                  <h5 color="primary">
                    <span>{{ "fullAnswerImage" | translate }}</span>
                  </h5>
                  <label *ngIf="fAIFileList.length > 0" class="upload-label" for="fullAnswerImageL" appDrag>
                    <img [src]="fAIUrlList | secure | async" width="80%" alt="" class="pointer"/>
                    <i class="material-icons pointer close-icon" (click)="deleteUploadedFAImage()">close</i>
                  </label>
                  <label *ngIf="fAIFileList.length === 0" class="upload-label" for="fullAnswerImageT" appDrag>
                    <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                    <input class="upload-thumbnail-id" type="file" (change)="fAISelectFile($event)" accept="image/*" name="photo" id="fullAnswerImageT"/>
                  </label>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div class="row text-center">
            <div class="col">
              <br />
              <br />
              <br />
              <button
                mat-raised-button
                (click)="addEdit()"
                [disabled]="!form.valid"
                color="primary"
              >
                <span *ngIf="!objectId">{{
                  "addNewQuestionExam" | translate
                }}</span>
                <span *ngIf="objectId">{{
                  "editQuestionExam" | translate
                }}</span>
              </button>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
