import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FullServiceService } from "./../../../services/full-service.service";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ToastrManager } from "ng6-toastr-notifications";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Exam } from "../../../models/category";

// import Adapter from "../../services/ckeditorAdapter";
import Adapter from "../../services/ckeditorAdapter";
import * as ClassicEditor from "../../../ckeditor5/build/ckeditor";

@Component({
  selector: "app-add-question",
  templateUrl: "./add-question.component.html",
  styleUrls: ["./add-question.component.scss"],
})
export class AddQuestionComponent implements OnInit {
  exams: Exam[];
  form: UntypedFormGroup;
  objectId: string;
  progress = 0;

  progressQI = 0;
  qIFileList = [];
  qIUrlList: string;
  qIList: File = null;
  progressFAI = 0;
  fAIFileList = [];
  fAIUrlList: string;
  fAIList: File = null;


// start-answer-image
// 1
progressA1 = 0;
a1FileList = [];
a1UrlList: string;
a1List: File = null;
// 2
progressA2 = 0;
a2FileList = [];
a2UrlList: string;
a2List: File = null;
// 3
progressA3 = 0;
a3FileList = [];
a3UrlList: string;
a3List: File = null;
// 4
progressA4 = 0;
a4FileList = [];
a4UrlList: string;
a4List: File = null;
// end-answer-image

  thumbnailFileList = [];
  thumbnailUrlList2 = [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;

  coverFileList: any = [];
  coverUrlList: string;
  coverUrlAfterUpload: any;
  coverList: File = null;
  public Editor: any = ClassicEditor;
  editorConfig = {
    language: "en",

    extraPlugins:
      "dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
      "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
      "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
      "filebrowser,find,fakeobjects,floatingspace,listblock,richcombo," +
      "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
      "indentblock,indentlist,justify,link,list,liststyle,magicline," +
      "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
      "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
      "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
      "tabletools,templates,toolbar,undo",
    // }
  };

  
thumbnailFileListV = [];
thumbnailUrlListV: string;
thumbnailListV: File = null;
progressV = 0;
progressVideoImage = 0;
videoImageFileList: any = [];
videoImageUrlList: string;
videoImageUrlAfterUpload: any;
videoImageList: File = null;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private activateRouter: ActivatedRoute
  ) {
    this.getAllExams();
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      questionAr: new UntypedFormControl("", []),
      questionEn: new UntypedFormControl("", []),
      questionFr: new UntypedFormControl("", []),
      answer1En: new UntypedFormControl("x", [Validators.required]),
      answer1Fr: new UntypedFormControl("", []),
      answer1Ar: new UntypedFormControl("", []),
      answer1Image: new UntypedFormControl("", []),
      answer1isTrue: new UntypedFormControl(false, [Validators.required]),
      answer2En: new UntypedFormControl("x", [Validators.required]),
      answer2Fr: new UntypedFormControl("", []),
      answer2Ar: new UntypedFormControl("", []),
      answer2Image: new UntypedFormControl("", []),
      answer2isTrue: new UntypedFormControl(false, [Validators.required]),
      answer3En: new UntypedFormControl("x", [Validators.required]),
      answer3Fr: new UntypedFormControl("", []),
      answer3Ar: new UntypedFormControl("", []),
      answer3Image: new UntypedFormControl("", []),
      answer3isTrue: new UntypedFormControl(false, [Validators.required]),
      answer4En: new UntypedFormControl("x", [Validators.required]),
      answer4Fr: new UntypedFormControl("", []),
      answer4Ar: new UntypedFormControl("", []),
      answer4Image: new UntypedFormControl("", []),
      answer4isTrue: new UntypedFormControl(true, [Validators.required]),
      fullAnswer: new UntypedFormControl("", []),
      exam: new UntypedFormControl("", [Validators.required]),
      degree: new UntypedFormControl(5, [Validators.required]),
      questionImage: new UntypedFormControl("", []),
      fullAnswerImage: new UntypedFormControl("", []),

      videoImage: new UntypedFormControl('', []),
      video: new UntypedFormControl('', []),
    });

    this.objectId = this.activateRouter.snapshot.params.id;
    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  getObject(id: string) {
    this.full.getOneQuestion(this.objectId).subscribe(
      (data: any) => {
        if (data.data?.answers.length) {
          const answers = {
            answer1En: data.data.answers[0].answerEn,
            answer1Fr: data.data.answers[0].answerFr,
            answer1Ar: data.data.answers[0].answerAr,
            answer1Image: data.data.answers[0]?.image || '',
            answer1isTrue: data.data.answers[0].isTrue,
            answer2En: data.data.answers[1].answerEn,
            answer2Fr: data.data.answers[1].answerFr,
            answer2Ar: data.data.answers[1].answerAr,
            answer2Image: data.data.answers[1]?.image || '',
            answer2isTrue: data.data.answers[1].isTrue,
            answer3En: data.data.answers[2].answerEn,
            answer3Fr: data.data.answers[2].answerFr,
            answer3Ar: data.data.answers[2].answerAr,
            answer3Image: data.data.answers[2]?.image || '',
            answer3isTrue: data.data.answers[2].isTrue,
            answer4En: data.data.answers[3].answerEn,
            answer4Fr: data.data.answers[3].answerFr,
            answer4Ar: data.data.answers[3].answerAr,
            answer4Image: data.data.answers[3]?.image || '',
            answer4isTrue: data.data.answers[3].isTrue,
          };
          this.form.patchValue(answers);
        }
        this.form.patchValue(data.data);
        if(data?.data?.fullAnswer){
          this.form.get("fullAnswer").setValue(this.htmlDecode(data?.data?.fullAnswer));
        }

        this.thumbnailFileList = data?.data?.image || [];
        this.thumbnailUrlList = data?.data?.image || '';

        this.qIFileList = data?.data?.questionImage || this.qIFileList;
        this.qIUrlList = data?.data?.questionImage || this.qIUrlList;

        this.fAIFileList = data?.data?.fullAnswerImage || this.fAIFileList;
        this.fAIUrlList = data?.data?.fullAnswerImage || this.fAIUrlList;

        this.a1FileList = data?.data?.answers[0].image || this.a1FileList;
        this.a1UrlList = data?.data?.answers[0].image || this.a1UrlList;
        this.a2FileList = data?.data?.answers[1].image || this.a2FileList;
        this.a2UrlList = data?.data?.answers[1].image || this.a2UrlList;
        this.a3FileList = data?.data?.answers[2].image || this.a3FileList;
        this.a3UrlList = data?.data?.answers[2].image || this.a3UrlList;
        this.a4FileList = data?.data?.answers[3].image || this.a4FileList;
        this.a4UrlList = data?.data?.answers[3].image || this.a4UrlList;


        this.thumbnailFileListV = data?.data?.video || [];
        this.thumbnailUrlListV = data.data.video;
        this.videoImageFileList = data.data.videoImage;
        this.videoImageUrlList = data.data.videoImage;
      },
      (err) => {
        this.full.openSnackBar(err || "", "أغلق", "danger");
      }
    );
  }

  thumbnailSelectFile(event) {
    this.thumbnailFileList = [];
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  thumbnailUpload() {
    let fd = new FormData();

    fd.append(
      "images",
      this.thumbnailFileList[0],
      this.thumbnailFileList[0].name
    );
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              image: event.body.images[0],
            });
            this.thumbnailUrlList = event.body.images[0];
        }
      },
      (err) => {
        this.thumbnailUrlList = this.translate.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translate.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      image: null,
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }

  coverSelectFile(event) {
    this.coverFileList = [];
    this.coverList = event.target.files[0];
    this.coverFileList.push(this.coverList);
    this.coverUpload();
  }

  coverUpload() {
    let fd = new FormData();

    fd.append("images", this.coverFileList[0], this.coverFileList[0].name);
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              coverImage: event.body.images[0],
            });
            this.coverUrlList = event.body.images[0];
        }
      },
      (err) => {
        this.coverUrlList = this.translate.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translate.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedCoverImage() {
    this.form.patchValue({
      coverImage: null,
    });
    this.progress = 0;
    this.coverFileList = [];
  }

  addEdit() {
    const arr = [this.form.get("answer1isTrue").value ,this.form.get("answer2isTrue").value ,this.form.get("answer3isTrue").value ,this.form.get("answer4isTrue").value];
    const count = arr.filter((value) => value).length;
    if(count !== 1){
      this.toastr.warningToastr(this.translate.instant('must detect one answer as true'));
      return;
    }
    if (
      !this.form.get("answer1isTrue").value &&
      !this.form.get("answer2isTrue").value &&
      !this.form.get("answer3isTrue").value &&
      !this.form.get("answer4isTrue").value
    ) {
      this.toastr.warningToastr(this.translate.instant('must detect one answer as true'));
      return;
    }
    if (!this.form.valid) {
      return false;
    }
    if (!this.objectId) {
      this.full.addQuestionForExam(this.form.value).subscribe(
        (data: any) => {
          this.router.navigateByUrl(
            `/exams-list/${this.form.value.exam}/questions`
          );
          this.full.openSnackBar(data.message || "", "أغلق", "success");
        },
        (err) => {
          this.full.openSnackBar(err || "", "أغلق", "danger");
        }
      );
    } else {
      this.full.editOneQuestion(this.objectId, this.form.value).subscribe(
        (response: any) => {
          this.router.navigateByUrl(
            `/exams-list/${this.form.value.exam}/questions`
          );
          this.full.openSnackBar(response.message || "", "أغلق", "success");
        },
        (error) => {
          this.full.openSnackBar(error || "", "أغلق", "danger");
        }
      );
    }
  }
  getAllExams() {
    this.full.getAllExamsForDropdown(1, 500, "", true, false).subscribe(
      (response: any) => {
        this.exams = response.data;
      },
      (error) => {}
    );
  }

  filesSelectFile(event) {
    this.thumbnailFileList = [];
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.fileUpload();
  }

  fileUpload() {
    let fd = new FormData();

    fd.append(
      "images",
      this.thumbnailFileList[0],
      this.thumbnailFileList[0].name
    );
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form
              .get("files")
              .setValue([
                ...this.form.get("files").value,
                {
                  name: this.thumbnailFileList[0].name,
                  url: event.body.images[0],
                },
              ]);
            this.thumbnailUrlList2.push({
              name: this.thumbnailFileList[0].name,
              url: event.body.images[0],
            });
          // this.form.patchValue({
          //   files: event.body.images
          // });
          // this.thumbnailUrlList = event.body.images;
        }
      },
      (err) => {
        this.thumbnailUrlList = this.translate.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translate.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedFiles(file) {
    const files = this.form.get("files").value;
    const newFiles = files.filter((e) => e.url !== file);
    this.form.get("files").setValue(newFiles);
    // this.form.patchValue({
    //   image: null
    // });
    // this.progress = 0;
    if (!newFiles.length) {
      this.progress = 0;
    }
    this.thumbnailUrlList2 = newFiles;
  }

  onReady(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new Adapter(loader, editor.config);
    };
  }
  

  htmlDecode(input) {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  }


  qISelectFile(event) {
    this.qIFileList = [];
    this.qIList = event.target.files[0];
    this.qIFileList.push(this.qIList);
    this.qIUpload();
  }

  qIUpload() {
    let fd = new FormData();
    fd.append("images", this.qIFileList[0], this.qIFileList[0].name);
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progressQI = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              questionImage: event.body.images[0],
            });
            this.qIUrlList = event.body.images[0];
        }
      },
      (err) => {
        this.qIUrlList = this.translate.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translate.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedQImage() {
    this.form.patchValue({
      questionImage: null,
    });
    this.progressQI = 0;
    this.qIFileList = [];
  }

  fAISelectFile(event) {
    this.fAIFileList = [];
    this.fAIList = event.target.files[0];
    this.fAIFileList.push(this.fAIList);
    this.fAIUpload();
  }

  fAIUpload() {
    let fd = new FormData();
    fd.append("images", this.fAIFileList[0], this.fAIFileList[0].name);
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progressFAI = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              fullAnswerImage: event.body.images[0],
            });
            this.fAIUrlList = event.body.images[0];
        }
      },
      (err) => {
        this.fAIUrlList = this.translate.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translate.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedFAImage() {
    this.form.patchValue({
      fullAnswerImage: null,
    });
    this.progressFAI = 0;
    this.fAIFileList = [];
  }

    // answer-
    aSelectFile(event, index) {
      switch (index) {
        case 1:
          this.a1FileList = [];
          this.a1List = event.target.files[0];
          this.a1FileList.push(this.a1List);
          break;
        case 2:
          this.a2FileList = [];
          this.a2List = event.target.files[0];
          this.a2FileList.push(this.a2List);
          break;
        case 3:
          this.a3FileList = [];
          this.a3List = event.target.files[0];
          this.a3FileList.push(this.a3List);
          break;
        case 4:
          this.a4FileList = [];
          this.a4List = event.target.files[0];
          this.a4FileList.push(this.a4List);
          break;
        default:
          break;
      }
      this.aUpload(index);
    }
  
    aUpload(index) {
      const fd3 = new FormData();
      // let file = null;
      switch (index) {
        case 1:
          // file = this.a1FileList
          fd3.append("images", this.a1FileList[0], this.a1FileList[0].name);
          break;
        case 2:
          // file = this.a2FileList
          fd3.append("images", this.a2FileList[0], this.a2FileList[0].name);
          break;
        case 3:
          // file = this.a3FileList
          fd3.append("images", this.a3FileList[0], this.a3FileList[0].name);
          break;
        case 4:
          // file = this.a4FileList
          fd3.append("images", this.a4FileList[0], this.a4FileList[0].name);
          break;
        default:
          break;
      }
      // if(!file) return;
      // fd.append("images", file[0], file[0].name);
      this.full.uploadImages(fd3).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              switch (index) {
                case 1:
                  this.progressA1 = Math.round((event.loaded / event.total) * 100);
                  break;
                  case 2:
                  this.progressA2 = Math.round((event.loaded / event.total) * 100);
                  break;
                  case 3:
                  this.progressA3 = Math.round((event.loaded / event.total) * 100);
                  break;
                  case 4:
                  this.progressA4 = Math.round((event.loaded / event.total) * 100);
                  break;
                default:
                  break;
              }
              break;
            case HttpEventType.Response:
              switch (index) {
                case 1:
                  this.form.patchValue({
                    answer1Image: event.body.images[0],
                  });
                  this.a1UrlList = event.body.images[0];
                  break;
                case 2:
                  this.form.patchValue({
                    answer2Image: event.body.images[0],
                  });
                  this.a2UrlList = event.body.images[0];
                  break;
                case 3:
                  this.form.patchValue({
                    answer3Image: event.body.images[0],
                  });
                  this.a3UrlList = event.body.images[0];
                  break;
                case 4:
                  this.form.patchValue({
                    answer4Image: event.body.images[0],
                  });
                  this.a4UrlList = event.body.images[0];
                  break;
                default:
                  break;
              }
          }
        },
        (err) => {
          switch (index) {
            case 1:
              this.a1UrlList = this.translate.instant("imageNotUploaded");
              break;
            case 2:
              this.a2UrlList = this.translate.instant("imageNotUploaded");
              break;
            case 3:
              this.a3UrlList = this.translate.instant("imageNotUploaded");
              break;
            case 4:
              this.a4UrlList = this.translate.instant("imageNotUploaded");
              break;
            default:
              break;
          }
        }
      );
      this.full.openSnackBar(
        this.translate.instant("filesUploadedSuccessfully"),
        "أغلق",
        "success"
      );
    }
  
    deleteUploadedAImage(index) {
      switch (index) {
        case 1:
          this.form.patchValue({
            answer1Image: null,
          });
          this.progressA1 = 0;
          this.a1FileList = [];
          break;
        case 2:
          this.form.patchValue({
            answer2Image: null,
          });
          this.progressA2 = 0;
          this.a2FileList = [];
          break;
        case 3:
          this.form.patchValue({
            answer3Image: null,
          });
          this.progressA3 = 0;
          this.a3FileList = [];
          break;
        case 4:
          this.form.patchValue({
            answer4Image: null,
          });
          this.progressA4 = 0;
          this.a4FileList = [];
          break;
        default:
          break;
      }
  
    }


    videoSelectFile(event) {
      this.thumbnailFileListV = [];
      this.thumbnailListV = event.target.files[0];
      this.thumbnailFileListV.push(this.thumbnailListV);
      this.videoUpload();
    }
  
    videoUpload() {
      let fd = new FormData();
  
      fd.append('my-video', this.thumbnailFileListV[0], this.thumbnailFileListV[0].name);
      this.full.uploadVideos(fd).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progressV = Math.round(event.loaded / event.total * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              video: event.body.videos[0]
            });
            this.thumbnailUrlListV = event.body.videos[0];
        }
      }, err => {
        this.thumbnailUrlListV = this.translate.instant('imageNotUploaded');
      });
      this.full.openSnackBar(this.translate.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
    }
    deleteUploadedVideo() {
      this.form.patchValue({
        videoImage: null
      });
      this.progressV = 0;
      this.thumbnailFileListV = [];
      this.form.get('video').setValue('');
      this.form.get('videoImage').setValue('');
    }
  
  
  
    videoImageSelectFile(event) {
      this.videoImageFileList = [];
      this.videoImageList = event.target.files[0];
      this.videoImageFileList.push(this.videoImageList);
      this.videoImageUpload();
    }
  
    videoImageUpload() {
      let fd = new FormData();
  
      fd.append('images', this.videoImageFileList[0], this.videoImageFileList[0].name);
      this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progressVideoImage = Math.round(event.loaded / event.total * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              videoImage: event.body.images[0]
            });
            this.videoImageUrlList = event.body.images[0];
        }
      }, err => {
        this.videoImageUrlList = this.translate.instant('imageNotUploaded');
      });
      this.full.openSnackBar(this.translate.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
    }
  
    deleteUploadedVideoImage() {
      this.form.patchValue({
        videoImage: null
      });
      this.progressVideoImage = 0;
      this.videoImageFileList = [];
    }
}
