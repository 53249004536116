import { FullServiceService } from './../../services/full-service.service';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Article } from '../../models/article';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  pageEvent;
  activeForm: UntypedFormGroup;
  data: any[] = [];

  displayedColumns: string[] = ['question', 'answer', 'edit', 'delete'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private translate: TranslateService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.getArticles(1);
  }

  ngOnInit() {
    this.activeForm = new UntypedFormGroup({
      isPublish: new UntypedFormControl('', [])
    });
  }

  getArticles(event: any) {
    console.log('asd', event);
    
    this.full.getAllFaqs(event.pageIndex + 1 || 1, event.pageSize, '').subscribe(
      (data: any) => {
        this.data = data.data;
        this.dataSource = new MatTableDataSource(this.data);
        // this.length = data.itemCount;
        this.length = data.paging.total;
        this.pageSize = data.paging.pageSize;
      },
      err => {
      }
    );
  }

  delete(id) {
    this.full.deleteOneFaq(id).subscribe((data: any) => {
      this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
      this.full.openSnackBar(this.translate.instant('faqDeletedSuccess'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(this.translate.instant('errorWhenDelet'), 'أغلق', 'danger');
    });
  }

  htmlDecode(input) {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  }

  confirmDialog(id): void {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openImage(image: string): void {
  }

  activate(article: Article, event: MatSlideToggleChange) {
    this.activeForm.patchValue({
      isPublish: event.checked
    });
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }
}
