import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FullServiceService } from './../../../services/full-service.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LessCat, MaterialDoc } from '../../../models/category';

import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AddFileModalComponent } from '../add-file-modal/add-file-modal.component';

@Component({
  selector: 'app-add-material-files',
  templateUrl: './add-material-files.component.html',
  styleUrls: ['./add-material-files.component.scss']
})
export class AddMaterialFilesComponent {



  displayedColumns = [
    'name',
    'edit',
    'delete',
  ];

  tabelKeys = [
    { label: 'name', key: 'name', id: '_id', type: 'text' },
  ];


  lessonsCategories: LessCat[];
  docs: MaterialDoc[] = [];
  form: UntypedFormGroup;
  objectId: string;
  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList2= [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;

  coverFileList: any = [];
  coverUrlList: string;
  coverUrlAfterUpload: any;
  coverList: File = null;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private activateRouter: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.getAllLessonsCats()
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      titleAr: new UntypedFormControl('', [Validators.required]),
      titleEn: new UntypedFormControl('', [Validators.required]),
      titleFr: new UntypedFormControl('', [Validators.required]),
      image: new UntypedFormControl('', []),
      lessonsCat: new UntypedFormControl('', [Validators.required]),
    });

    this.objectId = this.activateRouter.snapshot.params.id;
    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  getObject(id: string) {
    this.full.getOneMaterialFile(this.objectId).subscribe((data: any) => {
      this.form.patchValue(data.data);
      if(data.data.files.length){
        this.getMaterialFilesDoc()
      }
      this.thumbnailFileList = data.data.image;
      this.thumbnailUrlList = data.data.image;
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  thumbnailSelectFile(event) {
    this.thumbnailFileList = [];
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  thumbnailUpload() {
    let fd = new FormData();

    fd.append('images', this.thumbnailFileList[0], this.thumbnailFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            image: event.body.images[0]
          });
          this.thumbnailUrlList = event.body.images[0];
      }
    }, err => {
      this.thumbnailUrlList = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      image: null
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }
    if (!this.objectId) {
      this.full.addMaterialFile(this.form.value).subscribe((data: any) => {
        this.router.navigateByUrl('/material-files-list');
        this.full.openSnackBar(data.message || '', 'أغلق', 'success');
      }, err => {
        this.full.openSnackBar(err || '', 'أغلق', 'danger');
      });
    } else {
      this.full.editOneMaterialFile(this.objectId, this.form.value)
        .subscribe((response: any) => {
          this.router.navigateByUrl('/material-files-list');
          this.full.openSnackBar(response.message || '', 'أغلق', 'success');
        }, (error) => {
          this.full.openSnackBar(error || '', 'أغلق', 'danger');
        });
    }
  }
  getAllLessonsCats(){
    this.full.getAllLessonsCategoriesForDropdown(1, 500, '', true, false)
    .subscribe((response: any) => {
      this.lessonsCategories = response.data;
    }, (error) => {
    });
  }

  getMaterialFilesDoc(){
    this.full.getMaterialFileDocs(this.objectId)
    .subscribe((response: any) => {
      console.log('res', response);
      if(response.state){
        this.docs = response.data;
      }
      }, (error) => {
    });
  }

  editDocInMaterialFile(id: string) {
    const dialogRef = this.dialog.open(AddFileModalComponent, {
      data: {materialFile: id, model: 'material-docs', objectId: id},
      width: '60vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result && result === true){
        this.getMaterialFilesDoc();
      }
    });
  }
  confirmDeleteDocDialog(id: string) {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.deleteDoc(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  
  deleteDoc(id) {
    this.full.deleteMaterialDoc(id).subscribe((data: any) => {
      this.getMaterialFilesDoc()
      this.full.openSnackBar(this.translate.instant('articleDeletedSuccess'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(this.translate.instant('errorWhenDelet'), 'أغلق', 'danger');
    });
  }
}
