<div class="container container-top-margin add-container">
    <div class="row">
      <div class="col">
        <div class="page-head">
          <div class="flex flex-centerd">
            <i class="uil uil-book-medical"></i>
            <div class="flex-column">
              <h4 class="h4-responsive text-center" *ngIf="!objectId">
                {{ "addNewDocToMaterialFile" | translate }}
              </h4>
              <h4 class="h4-responsive text-center" *ngIf="objectId">
                {{ "editMaterialFileDoc" | translate }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-card class="add-card">
          <form [formGroup]="form">
            <div class="progress form-group">
              <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar" [style.width.%]="progress" ></div>
            </div>
            <div class="row text-center">
              <div class="col">
                <h5 color="primary">
                  <span>{{ "file" | translate }}</span>
                </h5>
                <label *ngIf="thumbnailFileList.length > 0" class="upload-label" for="upload-photo" appDrag >
                  <p>Open a PDF file<a [href]="thumbnailUrlList" target="_blank" style="margin: 0 20px" >{{'--here--' | translate}}</a>.
                  </p>
                  <br />
                  <i style="position: relative; margin: 0 20px" class="material-icons pointer close-icon" (click)="deleteUploadedFiles(thumbnailUrlList)" >close</i >
                </label>
                <label *ngIf="thumbnailFileList.length === 0" class="upload-label" for="upload-thumbnail" appDrag >
                  <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                  <input type="file" accept="application/pdf" (change)="filesSelectFile($event)" name="photo" id="upload-thumbnail" />
                </label>
                <br />
                <br />
              </div>
            </div>
  
            <div class="row text-center">
              <div class="col-4 margin-auto">
                <mat-form-field class="full-width">
                  <input
                    formControlName="nameEn"
                    matInput
                    placeholder="{{ 'nameEn' | translate }}"
                  />
                </mat-form-field>
              </div>
              <div class="col-4 margin-auto">
                <mat-form-field class="full-width">
                  <input
                    formControlName="nameFr"
                    matInput
                    placeholder="{{ 'nameFr' | translate }}"
                  />
                </mat-form-field>
              </div>
              <!-- </div> -->
              <!-- <div class="row text-center"> -->
              <div class="col-4 margin-auto">
                <mat-form-field class="full-width">
                  <input
                    formControlName="nameAr"
                    matInput
                    placeholder="{{ 'nameAr' | translate }}"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row text-center">
              <div class="col">
                <br />
                <br />
                <br />
                <button
                  mat-raised-button
                  (click)="addEdit()"
                  [disabled]="!form.valid"
                  color="primary"
                >
                  <span *ngIf="!objectId">{{ "addNewFile" | translate }}</span>
                  <span *ngIf="objectId">{{ "editFile" | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
  