import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FullServiceService } from '../../../services/full-service.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-client-address',
  templateUrl: './client-address.component.html',
  styleUrls: ['./client-address.component.scss']
})
export class ClientAddressComponent implements OnInit {
  pageEvent;
  activeForm: UntypedFormGroup;
  data: any[] = [];

  displayedColumns: string[] = ['city', 'state','address', 'client', 'edit', 'delete'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // Modal Image
  image: string;

  // MatPaginator Inputs
  length: number;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageIndex;

  clientFilter: UntypedFormGroup;
  linkedUsers = [];
  clientSearch = '';

  cities = [];
  states = [];

  filterForm: UntypedFormGroup;

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {
   this.full.getAllParentCities().subscribe((data: any) => {
    this.cities = data.data;
  })
  }

  ngOnInit() {
    this.clientFilter = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
    })

    this.filterForm = new UntypedFormGroup({
      searchWord: new UntypedFormControl('', []),
      city: new UntypedFormControl(null, []),
      state: new UntypedFormControl(null, []),
      client: new UntypedFormControl(null, []),
      sortField: new UntypedFormControl('index', []),
      sortOrder: new UntypedFormControl(1, []),
    });

    this.full.getAllClientAddress(1, 8, this.clientFilter.value)
    .subscribe((linkedUsersData: any) => {
      this.linkedUsers = linkedUsersData.data;
      
    }, (error) => {
  });

  if(this.activatedRoute.snapshot.queryParams.filterId) {
    this.filterForm.patchValue({
      client: this.activatedRoute.snapshot.queryParams.filterId
    });
  }

  this.getArticles(1);

  }

  getStatesForCity(id) {
    this.full.getAllSubsForCity(1, 1000, id).subscribe((data: any) => {
      this.states = data.data;
    })
  }

  getArticles(event: any) {
    this.full.getAllClientAddress(event.pageIndex + 1 || 1, event.pageSize, this.filterForm.value).subscribe(
      (data: any) => {
        this.data = data.data;
        this.dataSource = new MatTableDataSource(this.data);
        // this.length = data.itemCount;
        this.length = data.paging.total;
        this.pageSize = data.paging.pageSize;
      },
      err => {
      }
    );
  }

  filter() {
    this.getArticles(1);
  }

  getAll() {
    this.filterForm.patchValue({
      searchWord: null,
      city: null,
      state: null,
      client: null,
    })
    this.router.navigateByUrl('/client-address-list')
    this.getArticles(1);
  }

  getAllLinkedUsers() {
    this.full.getAllClientBase(1, 50, this.clientFilter.value)
      .subscribe((response: any) => {
        this.linkedUsers = response.data;
      }, (error) => {
      });
  }

  changeLinkedUsersSearch() {
    if (this.clientSearch) {
      this.clientFilter.patchValue({
        searchWord: this.clientSearch,
      })
      this.full.getAllClientBase(1, 50, this.clientFilter.value)
        .subscribe((response: any) => {
          this.linkedUsers = response.data;
        }, (error) => {
        });
    }
  }

  delete(id) {
    this.full.deleteOneClientAddress(id).subscribe((data: any) => {
      this.dataSource.data = this.dataSource.data.filter(item => item._id !== id);
      this.full.openSnackBar(this.translate.instant('تم الحذف بنجاح'), 'أغلق', 'success');
    }, err => {
      this.full.openSnackBar(this.translate.instant('errorWhenDelet'), 'أغلق', 'danger');
    });
  }

  confirmDialog(id): void {
    const message = this.translate.instant('areYouSureDelete');

    const dialogData = new ConfirmDialogModel(this.translate.instant('deleteConfirm'), message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      // this.result = dialogResult;
      if (dialogResult) {
        this.delete(id);
      } else {
        this.full.openSnackBar('تم إلغاء عملية الحذف', 'أغلق', 'danger');
      }
    });
  }

  edit(id) {
    let url = window.location.href.replace('client-address-list', `edit-client-address/${id}`);
    window.open(url, '_blank')
  }

  goTo(url: string) {
    this.router.navigateByUrl('/' + url);
  }
}
