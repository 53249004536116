import { TranslateService } from '@ngx-translate/core';
import { FileHandle } from './../../directives/dragDrop.directive';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from './../../../services/full-service.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Category } from '../../classes/Category';

@Component({
  selector: 'app-add-slider',
  templateUrl: './add-slider.component.html',
  styleUrls: ['./add-slider.component.scss']
})
export class AddSliderComponent implements OnInit {
  products = [];
  categories: Category[];
  searchWord = '';
  form: UntypedFormGroup;

  objectId: string;

  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;


  thumbnailFileListV = [];
  thumbnailUrlListV: string;
  thumbnailListV: File = null;


  appFileList = [];
  appUrlList: string;
  appUrlAfterUpload: any;
  appList: File = null;

  
  progressV = 0;


  filterForm: UntypedFormGroup = new UntypedFormGroup({
    searchWord: new UntypedFormControl('', []),
    minPrice: new UntypedFormControl(0, []),
    maxPrice: new UntypedFormControl(999999999999, []),
    promoted: new UntypedFormControl(null, []),
    verified: new UntypedFormControl(null, []),
    category: new UntypedFormControl(null, []),
    user: new UntypedFormControl(null, []),
    sortField: new UntypedFormControl('index', []),
    sortOrder: new UntypedFormControl(1, []),
  });

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private activateRouter: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
    this.getCategoriesNames();
    this.getAllProducts(1);
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      link: new UntypedFormControl('', []),

      image: new UntypedFormControl('', [Validators.required]),
      video: new UntypedFormControl('', []),

      type: new UntypedFormControl('image', []),
      linkType: new UntypedFormControl('none', []),
      category: new UntypedFormControl(['000000000000000000000000'], []),
      productId: new UntypedFormControl('000000000000000000000000', []),
    });

    this.objectId = this.activateRouter.snapshot.params.id;

    if (this.objectId) {
      this.getObject(this.objectId);
    }

    if (localStorage.getItem('proId')) {
      this.form.patchValue({
        type: localStorage.getItem('proType'),
        productId: localStorage.getItem('proId'),
      })
    }
  }

  getCategoriesNames() {
    this.full.getAllParentCategoriesForDropdown(1, 500)
      .subscribe((response: any) => {
        this.categories = response.data;
      }, (error) => {
      });
  }

  ngOnDestroy() {
    localStorage.removeItem('proType')
    localStorage.removeItem('proId')
  }

  getObject(id: string) {
    this.full.getOneSlider(this.objectId).subscribe((data: any) => {
      const s = data.data;
      this.form.patchValue(s);

      if(!s.type || !s.type.includes(['image', 'video'])){
        this.form.get('type').setValue('image')
      }

      this.thumbnailFileList = s.image;
      this.thumbnailUrlList = s.image;

      this.thumbnailFileListV = s?.video || [];
      this.thumbnailUrlListV = s.video;
    }, err => {
      this.snackBar.open(err);
    });
  }

  thumbnailSelectFile(event) {

    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  thumbnailUpload() {

    let fd = new FormData();

    fd.append('images', this.thumbnailFileList[0], this.thumbnailFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            image: event.body.images[0]
          });
          this.thumbnailUrlList = event.body.images[0];
      }
    }, err => {
      this.thumbnailUrlList = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      image: null
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }


  changeSearch() {
    this.filterForm.patchValue({
      searchWord: this.searchWord,
    })
    this.getAllProducts(1);
  }

  getAllProducts(event: any) {
    this.full.getAllProducts(event.pageIndex + 1 || 1, event.pageSize, this.filterForm.value).subscribe((data: any) => {
      this.products = data.data;
    }, err => {
    });
  }

  // getAllProducts() {
  //   this.full.getAllProductsForDropDown(1, 100, this.searchWord)
  //     .subscribe((response: any) => {
  //       this.products = response.data;
  //     }, (error) => {
  //     });
  // }

  // changeSearch() {
  //   if (this.searchWord) {
  //     this.full.getAllProductsForDropDown(1, 10, this.searchWord)
  //       .subscribe((response: any) => {
  //         this.products = response.data;
  //       }, (error) => {
  //       });
  //   }
  // }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }

    if (!this.objectId) {
      this.full.addSlider(this.form.value).subscribe((data: any) => {
        this.toastr.successToastr('تمت إضافة الإعلان بنجاح');
        this.router.navigateByUrl('/slider-list');
      }, err => {
        this.toastr.errorToastr(err);
      });
    } else {
      this.full.editOneSlider(this.objectId, this.form.value)
        .subscribe((response: any) => {
          this.toastr.successToastr('حدث خطأ أثناء إضافة الإعلان');
          this.router.navigateByUrl('/slider-list');
        }, (error) => {
          this.toastr.errorToastr(error);
        });
    }
  }

  changeType() {
    const type = this.form.get('type').value;
    // if(type === 'image') {
    //   this.form.get('video').clearValidators();
    // }
    // if(type === 'video') {
    //   this.form.get('video').addValidators([Validators.required])
    // }
  }



  videoSelectFile(event) {
    this.thumbnailFileListV = [];
    this.thumbnailListV = event.target.files[0];
    this.thumbnailFileListV.push(this.thumbnailListV);
    this.videoUpload();
  }

  videoUpload() {
    let fd = new FormData();

    fd.append('my-video', this.thumbnailFileListV[0], this.thumbnailFileListV[0].name);
    this.full.uploadVideos(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progressV = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            video: event.body.videos[0]
          });
          this.thumbnailUrlListV = event.body.videos[0];
      }
    }, err => {
      this.thumbnailUrlListV = this.translae.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translae.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }
  deleteUploadedVideo() {
    this.form.patchValue({
      image: null
    });
    this.progress = 0;
    this.thumbnailFileListV = [];
    this.form.get('video').setValue('');
  }
}
