import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Package } from '../../../models/category';
import { FullServiceService } from '../../../services/full-service.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { branches } from '../../../models/BaseURL';


@Component({
  selector: 'app-add-file-modal',
  templateUrl: './add-file-modal.component.html',
  styleUrls: ['./add-file-modal.component.scss']
})
export class AddFileModalComponent {
  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailList: File = null;

  form: UntypedFormGroup;
  materialFile: string;
  objectId: string | null;
  modelName: string;
  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<AddFileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {
    this.materialFile = this.data.materialFile;
    this.modelName = this.data.model;
    this.objectId = this.data.objectId;
    console.log('sd', this.data);    
    this.form = new UntypedFormGroup({
      nameAr: new UntypedFormControl('', [Validators.required]),
      nameEn: new UntypedFormControl('', [Validators.required]),
      nameFr: new UntypedFormControl('', [Validators.required]),
      file: new UntypedFormControl('', [Validators.required]),
      materialFile: new UntypedFormControl(this.materialFile, [Validators.compose([Validators.required])]),
    });

    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  

  addEdit() {
    if (!this.form.valid) {
      return false;
    }
    if (!this.objectId) {
      this.addDocToMaterialFileBranch()
    }else{
      this.editDocOFMaterialFileBranch()
    }
  }
  getObject(id: string) {
    this.full.getOneMaterialDoc(this.objectId).subscribe((data: any) => {
      this.form.patchValue(data.data);
      this.thumbnailFileList = data.data.file;
      this.thumbnailUrlList = data.data.file;
    }, err => {
      this.full.openSnackBar(err || '', 'أغلق', 'danger');
    });
  }

  editDocOFMaterialFileBranch() {
    console.log('hth', this.form.value);

    this.full.editDocInMaterialFile(this.objectId, this.form.value).subscribe((resAddDocToMaterialFile: any) => {
      if(resAddDocToMaterialFile.state){
        this.toastr.successToastr(this.translate.instant('addDocToMaterialFileSuccessfully'));
        this.dialogRef.close(true);
      }
    }, err => {
      this.toastr.errorToastr(err);
    })
    
  }
  addDocToMaterialFileBranch() {
    this.full.addDocToMaterialFile(this.modelName, this.materialFile, this.form.value).subscribe((resAddDocToMaterialFile: any) => {
      if(resAddDocToMaterialFile.state){
        this.toastr.successToastr(this.translate.instant('addDocToMaterialFileSuccessfully'));
        this.dialogRef.close(true);
      }
    }, err => {
      this.toastr.errorToastr(err);
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  filesSelectFile(event) {
    this.thumbnailFileList = [];
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);    
    this.fileUpload();
  }

  fileUpload() {
    let fd = new FormData();

    fd.append('images', this.thumbnailFileList[0], this.thumbnailFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            file: event.body.images[0]
          });
          this.thumbnailUrlList = event.body.images[0];
      }
    }, err => {
      this.thumbnailUrlList = this.translate.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translate.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }
  deleteUploadedFiles(file) {
    this.form.patchValue({
      file: null
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }
}
