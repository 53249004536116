import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FullServiceService } from "../../services/full-service.service";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  form: UntypedFormGroup;

  // Modal Image
  image: string;

  fileList = [];
  list: File = null;
  progress = 0;
  urlList: string;
  urlAfterUpload: any;
  objectId: string;
  settings;

  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;

  config = {
    fontFamily: {
      options: [
        "default",
        "Ubuntu, Arial, sans-serif",
        "Ubuntu Mono, Courier New, Courier, monospace",
      ],
    },
    toolbar: [
      "undo",
      "redo",
      "|",
      "heading",
      "fontFamily",
      "fontSize",
      "|",
      "bold",
      "italic",
      "underline",
      "fontColor",
      "fontBackgroundColor",
      "highlight",
      "alignment",
      "|",
      "link",
      "imageUpload",
      "mediaEmbed",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "indent",
      "outdent",
      "|",
      "insertTable",
      "blockQuote",
      "specialCharacters",
    ],
    language: "ar",
    alignment: {
      options: ["left", "right"],
    },
  };

  constructor(
    private full: FullServiceService,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,
    private activateRouter: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      phone: new UntypedFormControl("", []),
      phone1: new UntypedFormControl("", []),
      email: new UntypedFormControl("", []),
      email1: new UntypedFormControl("", []),
      instagram: new UntypedFormControl("", []),
      whatsapp: new UntypedFormControl("", []),
      twitter: new UntypedFormControl("", []),
      facebook: new UntypedFormControl("", []),
      youtube: new UntypedFormControl("", []),

      addressAr: new UntypedFormControl("", []),
      addressEn: new UntypedFormControl("", []),

      aboutFooterAr: new UntypedFormControl("", []),
      aboutFooterEn: new UntypedFormControl("", []),

      aboutFirstTitleAr: new UntypedFormControl("", []),
      aboutFirstTitleEn: new UntypedFormControl("", []),

      aboutWebDesAr: new UntypedFormControl("", []),
      aboutWebDesEn: new UntypedFormControl("", []),

      aboutAppAr: new UntypedFormControl("", []),
      aboutAppEn: new UntypedFormControl("", []),

      condittionsAr: new UntypedFormControl("", []),
      condittionsEn: new UntypedFormControl("", []),

      privacyAr: new UntypedFormControl("", []),
      privacyEn: new UntypedFormControl("", []),

      verifiedText: new UntypedFormControl("", []),
      promotedText: new UntypedFormControl("", []),
      appVersion: new UntypedFormControl("", []),
      iosVersionCode: new UntypedFormControl("", []),
      forceUpdate: new UntypedFormControl("", []),
      canAdv: new UntypedFormControl("", []),
      advText: new UntypedFormControl("", []),
      isAppSliderActive: new UntypedFormControl(false, []),
      discountStatus: new UntypedFormControl(false, []),
      examDate: new UntypedFormControl("", []),
      isExamDateActive: new UntypedFormControl(false, []),
      freeTrailDays: new UntypedFormControl(0, [Validators.required, Validators.min(0)]),
    });

    // this.objectId = this.activateRouter.snapshot.params.id;
    // if (this.objectId) {
    this.getObject();
    // }
  }

  getObject() {
    this.full.getSettings().subscribe(
      (response: any) => {
        this.form.patchValue(response.data[0]);
        const s = response.data[0];
        this.settings = response.data[0];

        s.aboutAppAr = this.htmlDecode(s.aboutAppAr);
        s.aboutAppEn = this.htmlDecode(s.aboutAppEn);

        s.condittionsEn = this.htmlDecode(s.condittionsEn);
        s.condittionsAr = this.htmlDecode(s.condittionsAr);

        s.privacyAr = this.htmlDecode(s.privacyAr);
        s.privacyEn = this.htmlDecode(s.privacyEn);

        this.form.patchValue(s);
        this.form.patchValue({
          forceUpdate: s.forceUpdate + "",
          canAdv: s.canAdv + "",
        });
      },
      (err) => {
        this.snackBar.open(err);
      }
    );
  }

  htmlDecode(input) {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  }

  edit() {
    if (!this.form.valid) {
      return false;
    }

    this.full.editSetting(this.settings._id, this.form.value).subscribe(
      (data: any) => {
        this.full.openSnackBar(
          this.translate.instant("settingsEdited"),
          "أغلق",
          "success"
        );
      },
      (err) => {
        this.full.openSnackBar(err || "", "أغلق", "danger");
      }
    );
  }
}
