<div class="container container-top-margin add-container">
  <div class="row">
    <div class="col">
      <div class="page-head">
        <div class="flex flex-centerd">
          <i class="uil uil-book-medical"></i>
          <div class="flex-column">
            <h4 class="h4-responsive text-center" *ngIf="!objectId">
              {{ "addNewExam" | translate }}
            </h4>
            <h4 class="h4-responsive text-center" *ngIf="objectId">
              {{ "editExam" | translate }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-stepper orientation="vertical" [linear]="true" #stepper>
        <mat-step [stepControl]="firstFormGroup">
          <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>{{'selectMaterial' | translate}}</ng-template>
              <mat-form-field class="full-width">
                <mat-label>{{ "lessonsCat" | translate }}</mat-label>
                <mat-select placeholder="{{ '' | translate }}" formControlName="lessonsCat" >
                  <mat-option *ngFor="let g of lessonsCategories" [value]="g._id">
                    {{ g.nameEn }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            <div>
              <button mat-button matStepperNext (click)="getUnitsForLessonsCat()">{{'next' | translate}}</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="form">
          <mat-card class="add-card">
            <form [formGroup]="form">
              <div class="progress form-group">
                <div
                  class="progress-bar progress-bar-striped bg-success-bar"
                  role="progressbar"
                  [style.width.%]="progress"
                ></div>
              </div>
              <div class="row text-center">
                <div class="col">
                  <h5 color="primary">
                    <span>{{ "image" | translate }}</span>
                  </h5>
                  <label
                    *ngIf="thumbnailFileList.length > 0"
                    class="upload-label"
                    for="upload-photo"
                    appDrag
                  >
                    <img
                      [src]="thumbnailUrlList | secure | async"
                      width="80%"
                      alt=""
                      class="pointer"
                    />
                    <i
                      class="material-icons pointer close-icon"
                      (click)="deleteUploadedThumbnailImage()"
                    >
                      close
                    </i>
                  </label>
                  <label
                    *ngIf="thumbnailFileList.length === 0"
                    class="upload-label"
                    for="upload-thumbnail"
                    appDrag
                  >
                    <img
                      class="pointer"
                      src="https://www.greatmats.com/images/placeholder-all.png"
                      width="80%"
                      alt=""
                    />
                    <input
                      type="file"
                      (change)="thumbnailSelectFile($event)"
                      accept="image/*"
                      name="photo"
                      id="upload-thumbnail"
                    />
                  </label>
                  <br />
                  <br />
                </div>
              </div>
              <!-- <div class="row text-center">
                <div class="col-12 margin-auto">
                  <mat-form-field class="full-width">
                    <input
                      formControlName="questionAr"
                      matInput
                      placeholder="{{ 'questionAr' | translate }}"
                    />
                  </mat-form-field>
                </div>
              </div> -->
              <div class="row text-center">
                <div class="col-12 margin-auto">
                  <mat-form-field class="full-width">
                    <input
                      formControlName="questionEn"
                      matInput
                      placeholder="{{ 'question' | translate }}"
                    />
                  </mat-form-field>
                </div>
              </div>
              <!-- <div class="row text-center">
                <div class="col-12 margin-auto">
                  <mat-form-field class="full-width">
                    <input
                      formControlName="questionFr"
                      matInput
                      placeholder="{{ 'questionFr' | translate }}"
                    />
                  </mat-form-field>
                </div>
              </div> -->
              <div class="row text-center">
                <div class="col-12">
                  <!-- <mat-form-field class="full-width">
                    <mat-label>{{ "lessonsCat" | translate }}</mat-label>
                    <mat-select
                      placeholder="{{ '' | translate }}"
                      formControlName="lessonsCat"
                    >
                      <mat-option
                        *ngFor="let g of lessonsCategories"
                        [value]="g._id"
                      >
                        {{ g.nameEn }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->
                    <mat-form-field class="full-width">
                      <mat-label>{{ "lessonUnit" | translate }}</mat-label>
                      <mat-select
                        placeholder="{{ '' | translate }}"
                        formControlName="unit"
                      >
                        <mat-option *ngFor="let g of units" [value]="g._id">
                          {{ g.nameEn }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field class="full-width">
                    <input
                      type="number"
                      formControlName="examTime"
                      matInput
                      placeholder="{{ 'examTime' | translate }}"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div class="row text-center">
                <div class="col-12">
                  <!-- <span>{{ "examTextAnswer" | translate }}</span> -->
                  <span>{{ "examText" | translate }}</span>
                  <!-- <ckeditor
                  formControlName="detailedAnswer"
                  [config]="editorConfig"
                  (ready)="onReady($event)"
                  rows="5"
                ></ckeditor> -->
                  <ckeditor
                    [editor]="Editor"
                    formControlName="detailedAnswer"
                  ></ckeditor>
                </div>
              </div>
              <div class="row text-center">
                <div class="col">
                  <br />
                  <br />
                  <br />
                  <button
                    mat-raised-button
                    (click)="addEdit()"
                    [disabled]="!form.valid"
                    color="primary"
                  >
                    <span *ngIf="!objectId">{{ "addNewExam" | translate }}</span>
                    <span *ngIf="objectId">{{ "editExam" | translate }}</span>
                  </button>
                </div>
              </div>
            </form>
          </mat-card>
        </mat-step>
      </mat-stepper>
    </div>
  </div>
</div>
