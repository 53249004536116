import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FullServiceService } from "./../../../services/full-service.service";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ToastrManager } from "ng6-toastr-notifications";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LessCat, Unit } from "../../../models/category";

import Adapter from "../../services/ckeditorAdapter";
// import { Alignment } from "@ckeditor/ckeditor5-alignment";
// import { Base64UploadAdapter } from '@ckeditor/ckeditor5-upload';
import * as ClassicEditor from "../../../ckeditor5/build/ckeditor";
@Component({
  selector: "app-add-exam",
  templateUrl: "./add-exam.component.html",
  styleUrls: ["./add-exam.component.scss"],
})
export class AddExamComponent implements OnInit {
  units: Unit[];
  lessonsCategories: LessCat[];
  form: UntypedFormGroup;
  firstFormGroup: UntypedFormGroup;
  objectId: string;
  progress = 0;
  thumbnailFileList = [];
  thumbnailUrlList2 = [];
  thumbnailUrlList: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;
  public Editor: any = ClassicEditor;
  coverFileList: any = [];
  coverUrlList: string;
  coverUrlAfterUpload: any;
  coverList: File = null;

  editorConfig = {
    language: "en",

    extraPlugins:
      "dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
      "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
      "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
      "filebrowser,find,fakeobjects,floatingspace,listblock,richcombo," +
      "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
      "indentblock,indentlist,justify,link,list,liststyle,magicline," +
      "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
      "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
      "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
      "tabletools,templates,toolbar,undo",
    // }
  };

  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private router: Router,
    private translae: TranslateService,
    private snackBar: MatSnackBar,
    private activateRouter: ActivatedRoute
  ) {
    this.getAllLessonsCats();
    // ClassicEditor
    // .create( document.querySelector( '#editor' ), {
    //     plugins: [ Alignment,],
    //     toolbar: [ 'alignment',]
    // } )
    // .then(console.log('asdas'))
    // .catch();
  }

  ngOnInit() {
    this.firstFormGroup = new UntypedFormGroup({
      lessonsCat: new UntypedFormControl('', [Validators.required]),
    });
    this.form = new UntypedFormGroup({
      questionAr: new UntypedFormControl("", []),
      questionEn: new UntypedFormControl("", []),
      questionFr: new UntypedFormControl("", []),
      detailedAnswer: new UntypedFormControl("", []),
      image: new UntypedFormControl("", []),
      examTime: new UntypedFormControl(90, [Validators.required]),
      lessonsCat: new UntypedFormControl("", [Validators.required]),
      unit: new UntypedFormControl('', [Validators.required]),
    });

    this.objectId = this.activateRouter.snapshot.params.id;
    if (this.objectId) {
      this.getObject(this.objectId);
    }
  }

  getObject(id: string) {
    this.full.getOneExam(this.objectId).subscribe(
      (data: any) => {
        this.firstFormGroup.get('lessonsCat')?.setValue(data.data.lessonsCat);
        this.form.patchValue(data.data);
        this.thumbnailFileList = data.data.image;
        this.thumbnailUrlList = data.data.image;
        this.form
          .get("detailedAnswer")
          .setValue(this.htmlDecode(data.data.detailedAnswer));
      },
      (err) => {
        this.full.openSnackBar(err || "", "أغلق", "danger");
      }
    );
  }

  thumbnailSelectFile(event) {
    this.thumbnailFileList = [];
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  thumbnailUpload() {
    let fd = new FormData();

    fd.append(
      "images",
      this.thumbnailFileList[0],
      this.thumbnailFileList[0].name
    );
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              image: event.body.images[0],
            });
            this.thumbnailUrlList = event.body.images[0];
        }
      },
      (err) => {
        this.thumbnailUrlList = this.translae.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translae.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      image: null,
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }

  coverSelectFile(event) {
    this.coverFileList = [];
    this.coverList = event.target.files[0];
    this.coverFileList.push(this.coverList);
    this.coverUpload();
  }

  coverUpload() {
    let fd = new FormData();

    fd.append("images", this.coverFileList[0], this.coverFileList[0].name);
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              coverImage: event.body.images[0],
            });
            this.coverUrlList = event.body.images[0];
        }
      },
      (err) => {
        this.coverUrlList = this.translae.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translae.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedCoverImage() {
    this.form.patchValue({
      coverImage: null,
    });
    this.progress = 0;
    this.coverFileList = [];
  }

  addEdit() {
    if (!this.form.valid) {
      return false;
    }
    if (!this.objectId) {
      this.full.addExam(this.form.value).subscribe(
        (data: any) => {
          this.router.navigateByUrl("/exams-list");
          this.full.openSnackBar(data.message || "", "أغلق", "success");
        },
        (err) => {
          this.full.openSnackBar(err || "", "أغلق", "danger");
        }
      );
    } else {
      this.full.editOneExam(this.objectId, this.form.value).subscribe(
        (response: any) => {
          this.router.navigateByUrl("/exams-list");
          this.full.openSnackBar(response.message || "", "أغلق", "success");
        },
        (error) => {
          this.full.openSnackBar(error || "", "أغلق", "danger");
        }
      );
    }
  }
  getAllLessonsCats() {
    this.full
      .getAllLessonsCategoriesForDropdown(1, 500, "", true, false)
      .subscribe(
        (response: any) => {
          this.lessonsCategories = response.data;
        },
        (error) => {}
      );
  }

  filesSelectFile(event) {
    this.thumbnailFileList = [];
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.fileUpload();
  }

  fileUpload() {
    let fd = new FormData();

    fd.append(
      "images",
      this.thumbnailFileList[0],
      this.thumbnailFileList[0].name
    );
    this.full.uploadImages(fd).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progress = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.get("files").setValue([
              ...this.form.get("files").value,
              {
                name: this.thumbnailFileList[0].name,
                url: event.body.images[0],
              },
            ]);
            this.thumbnailUrlList2.push({
              name: this.thumbnailFileList[0].name,
              url: event.body.images[0],
            });
          // this.form.patchValue({
          //   files: event.body.images
          // });
          // this.thumbnailUrlList = event.body.images;
        }
      },
      (err) => {
        this.thumbnailUrlList = this.translae.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translae.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedFiles(file) {
    const files = this.form.get("files").value;
    const newFiles = files.filter((e) => e.url !== file);
    this.form.get("files").setValue(newFiles);
    // this.form.patchValue({
    //   image: null
    // });
    // this.progress = 0;
    if (!newFiles.length) {
      this.progress = 0;
    }
    this.thumbnailUrlList2 = newFiles;
  }

  onReady(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new Adapter(loader, editor.config);
    };
  }

  htmlDecode(input) {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  }


  getUnitsForLessonsCat(){
    console.log(this.firstFormGroup.value);
    console.log(this.form.value);
    
    if(this.firstFormGroup.value.lessonsCat !== this.form.value.lessonsCat){
      this.form.get('unit').setValue(null);
    }
    this.full.getAllUnitsForMaterialForDropdown(this.firstFormGroup.value.lessonsCat)
    .subscribe((response: any) => {
      this.form.get('lessonsCat').setValue(this.firstFormGroup.value.lessonsCat);
      this.units = response.data;
    }, (error) => {
      console.log('e', error);
    });
  }
}
