import { FullServiceService } from "./../../services/full-service.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { Component, OnInit } from "@angular/core";
import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";
import * as ClassicEditor from "../../ckeditor5/build/ckeditor";
@Component({
  selector: "app-add-faq",
  templateUrl: "./add-faq.component.html",
  styleUrls: ["./add-faq.component.scss"],
})
export class AddFaqComponent implements OnInit {
  progressCover = 0;
  progress = 0;
  progressV = 0;
  thumbnailFileList = [];
  thumbnailUrlList: string;
  thumbnailFileListV = [];
  thumbnailUrlListV: string;
  thumbnailUrlAfterUpload: any;
  thumbnailList: File = null;
  thumbnailListV: File = null;


  deletedImages = [];
  form: UntypedFormGroup;
  id = "";
  brand: any;
  cutSizeImages = 4 / 4;
  cutSizeIcons = 4 / 4;
  public Editor: any = ClassicEditor;
  editorConfig = {
    language: "en",

    extraPlugins:
      "dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
      "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
      "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
      "filebrowser,find,fakeobjects,floatingspace,listblock,richcombo," +
      "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
      "indentblock,indentlist,justify,link,list,liststyle,magicline," +
      "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
      "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
      "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
      "tabletools,templates,toolbar,undo",
    // }
  };

  constructor(
    // private productService: CatSubProductsService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public dialog: MatDialog,
    private full: FullServiceService,
    private toastr: ToastrManager
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.params.id;

    this.form = new UntypedFormGroup({
      questionAr: new UntypedFormControl("", [Validators.required]),
      answerAr: new UntypedFormControl("", [Validators.required]),
      questionEn: new UntypedFormControl("", [Validators.required]),
      answerEn: new UntypedFormControl("", [Validators.required]),
      image: new UntypedFormControl('', []),
      video: new UntypedFormControl('', []),
      // index: new FormControl('', [])
    });

    if (this.id) {
      this.full.getOneFaq(this.id).subscribe((data: any) => {
        if (!data) {
          this.toastr.successToastr(
            this.translate.instant("addedSuccessfully")
          );
          this.router.navigateByUrl("/faq");
          return;
        } else {
          this.brand = data.data;

          this.brand.answerAr = this.htmlDecode(this.brand.answerAr);
          this.brand.answerEn = this.htmlDecode(this.brand.answerEn);

          this.thumbnailFileList = data?.data?.image || [];
          this.thumbnailFileListV = data?.data?.video || [];
          this.thumbnailUrlList = data.data.image;
          this.thumbnailUrlListV = data.data.video;

          this.form.patchValue({
            questionAr: this.brand.questionAr,
            answerAr: this.brand.answerAr,
            questionEn: this.brand.questionEn,
            answerEn: this.brand.answerEn,
            index: this.brand.index,
            image: this.brand?.image || '',
            video: this.brand?.video || '',
          });
        }
      });
    }
  }

  htmlDecode(input) {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes[0].nodeValue;
  }

  addOrEdit() {
    if (!this.id) {
      this.full.addNewFaq(this.form.value).subscribe(
        (message: string) => {
          this.router.navigateByUrl("/faq");
        },
        (err) => {}
      );
    } else {
      this.full.editFaq(this.id, this.form.value).subscribe(
        (message: string) => {
          this.router.navigateByUrl("/faq");
        },
        (err) => {}
      );
    }
  }

  valid(controlName, errorName) {
    return (
      this.form.get(controlName).hasError(errorName) &&
      (this.form.get(controlName).dirty || this.form.get(controlName).touched)
    );
  }



  thumbnailSelectFile(event) {
    this.thumbnailFileList = [];
    this.thumbnailList = event.target.files[0];
    this.thumbnailFileList.push(this.thumbnailList);
    this.thumbnailUpload();
  }

  thumbnailUpload() {
    let fd = new FormData();

    fd.append('images', this.thumbnailFileList[0], this.thumbnailFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            image: event.body.images[0]
          });
          this.thumbnailUrlList = event.body.images[0];
      }
    }, err => {
      this.thumbnailUrlList = this.translate.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translate.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  deleteUploadedThumbnailImage() {
    this.form.patchValue({
      image: null
    });
    this.progress = 0;
    this.thumbnailFileList = [];
  }

  videoSelectFile(event) {
    this.thumbnailFileListV = [];
    this.thumbnailListV = event.target.files[0];
    this.thumbnailFileListV.push(this.thumbnailListV);
    this.videoUpload();
  }

  videoUpload() {
    let fd = new FormData();

    fd.append('my-video', this.thumbnailFileListV[0], this.thumbnailFileListV[0].name);
    this.full.uploadVideos(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progressV = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            video: event.body.videos[0]
          });
          this.thumbnailUrlListV = event.body.videos[0];
      }
    }, err => {
      this.thumbnailUrlListV = this.translate.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translate.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }
  deleteUploadedVideo() {
    this.form.patchValue({
      image: null
    });
    this.progress = 0;
    this.thumbnailFileListV = [];
    this.form.get('video').setValue('');
  }
}
