<div class="container container-top-margin add-container">
    <div class="row">
      <div class="col">
        <div class="page-head">
          <div class="flex flex-centerd">
            <i class="uil uil-book-medical"></i>
            <div class="flex-column">
              <h4 class="h4-responsive text-center" *ngIf="!objectId">
                {{ "addNewMaterialFile" | translate }}
              </h4>
              <h4 class="h4-responsive text-center" *ngIf="objectId">
                {{ "editMaterialFile" | translate }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-card class="add-card">
          <form [formGroup]="form">
            <div class="progress form-group">
                <div class="progress-bar progress-bar-striped bg-success-bar" role="progressbar" [style.width.%]="progress" ></div>
              </div>
              <div class="row text-center">
                <div class="col">
                  <h5 color="primary">
                    <span>{{ "image" | translate }}</span>
                  </h5>
                  <label *ngIf="thumbnailFileList.length > 0" class="upload-label" for="upload-photo" appDrag >
                    <img [src]="thumbnailUrlList | secure | async" width="80%" alt="" class="pointer" />
                    <i class="material-icons pointer close-icon" (click)="deleteUploadedThumbnailImage()"> close</i>
                  </label>
                  <label *ngIf="thumbnailFileList.length === 0" class="upload-label" for="upload-thumbnail" appDrag >
                    <img class="pointer" src="https://www.greatmats.com/images/placeholder-all.png" width="80%" alt="" />
                    <input type="file" accept="image/*" (change)="thumbnailSelectFile($event)" name="photo" id="upload-thumbnail" />
                  </label>
                  <br />
                  <br />
                </div>
              </div>
            <div class="row text-center">
              <div class="col-6 margin-auto">
                <mat-form-field class="full-width">
                  <input
                    formControlName="titleEn"
                    matInput
                    placeholder="{{ 'titleEn' | translate }}"
                  />
                </mat-form-field>
              </div>
              <div class="col-6 margin-auto">
                <mat-form-field class="full-width">
                  <input
                    formControlName="titleFr"
                    matInput
                    placeholder="{{ 'titleFr' | translate }}"
                  />
                </mat-form-field>
              </div>
              <!-- </div> -->
              <!-- <div class="row text-center"> -->
 
            </div>
            <div class="row text-center">
              <div class="col-6">
                    <mat-form-field class="full-width">
                      <input
                        formControlName="titleAr"
                        matInput
                        placeholder="{{ 'titleAr' | translate }}"
                      />
                    </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field class="full-width">
                  <mat-label>{{ "lessonsCat" | translate }}</mat-label>
                  <mat-select
                    placeholder="{{ '' | translate }}"
                    formControlName="lessonsCat"
                  >
                    <mat-option
                      *ngFor="let g of lessonsCategories"
                      [value]="g._id"
                    >
                      {{ g.nameEn }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row text-center">
              <div class="col">
                <br />
                <br />
                <br />
                <button
                  mat-raised-button
                  (click)="addEdit()"
                  [disabled]="!form.valid"
                  color="primary"
                >
                  <span *ngIf="!objectId">{{ "addNewMaterialFile" | translate }}</span>
                  <span *ngIf="objectId">{{ "editMaterialFile" | translate }}</span>
                </button>
              </div>
            </div>
            <div class="row text-center">
              <div class="col">
                <br />
                <br />
                <div class="row">
                  <div class="col">
                    <div class="row" *ngFor="let doc of docs">
                      <div class="col col-4" style="text-align: start;">
                        {{doc.name}}
                      </div>
                      <div class="col col-1">
                        <a (click)="editDocInMaterialFile(doc._id)">
                          <i class="material-icons pointer"> edit </i>
                        </a>
                      </div>
                      <div class="col col-1">
                        <a (click)="confirmDeleteDocDialog(doc._id)">
                          <i class="material-icons pointer"> delete </i>
                        </a>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
  