import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
// import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {MatDialog, MatDialogRef, MatDialogModule, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { FullServiceService } from '../../../services/full-service.service';

import Adapter from "../../services/ckeditorAdapter";
import * as ClassicEditor from "../../../ckeditor5/build/ckeditor";

import { HttpEvent, HttpEventType } from "@angular/common/http";
@Component({
  selector: 'app-add-question-modal',
  templateUrl: './add-question-modal.component.html',
  styleUrls: ['./add-question-modal.component.scss']
})
export class AddQuestionModalComponent implements OnInit {
  form: UntypedFormGroup;

  progressQI = 0;
  qIFileList = [];
  qIUrlList: string;
  qIList: File = null;

  progressFAI = 0;
  fAIFileList = [];
  fAIUrlList: string;
  fAIList: File = null;
// start-answer-image
// 1
progressA1 = 0;
a1FileList = [];
a1UrlList: string;
a1List: File = null;
// 2
progressA2 = 0;
a2FileList = [];
a2UrlList: string;
a2List: File = null;
// 3
progressA3 = 0;
a3FileList = [];
a3UrlList: string;
a3List: File = null;
// 4
progressA4 = 0;
a4FileList = [];
a4UrlList: string;
a4List: File = null;
// end-answer-image

thumbnailFileListV = [];
thumbnailUrlListV: string;
thumbnailListV: File = null;
progressV = 0;
progressVideoImage = 0;
videoImageFileList: any = [];
videoImageUrlList: string;
videoImageUrlAfterUpload: any;
videoImageList: File = null;


  public Editor: any = ClassicEditor;
  editorConfig = {
    language: "en",

    extraPlugins:
      "dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
      "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
      "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
      "filebrowser,find,fakeobjects,floatingspace,listblock,richcombo," +
      "font,format,forms,horizontalrule,htmlwriter,iframe,image,indent," +
      "indentblock,indentlist,justify,link,list,liststyle,magicline," +
      "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
      "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
      "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
      "tabletools,templates,toolbar,undo",
    // }
  };
  constructor(
    private full: FullServiceService,
    private toastr: ToastrManager,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<AddQuestionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
    console.log(this.data);
    
    this.form = new UntypedFormGroup({
      questionAr: new UntypedFormControl('', []),
      questionEn: new UntypedFormControl('', []),
      questionFr: new UntypedFormControl('', []),
      answer1En: new UntypedFormControl('x', [Validators.required]),
      answer1Fr: new UntypedFormControl('', []),
      answer1Ar: new UntypedFormControl('', []),
      answer1Image: new UntypedFormControl('', []),
      answer1isTrue: new UntypedFormControl(false, [Validators.required]),
      answer2En: new UntypedFormControl('x', [Validators.required]),
      answer2Fr: new UntypedFormControl('', []),
      answer2Ar: new UntypedFormControl('', []),
      answer2Image: new UntypedFormControl('', []),
      answer2isTrue: new UntypedFormControl(false, [Validators.required]),
      answer3En: new UntypedFormControl('x', [Validators.required]),
      answer3Fr: new UntypedFormControl('', []),
      answer3Ar: new UntypedFormControl('', []),
      answer3Image: new UntypedFormControl('', []),
      answer3isTrue: new UntypedFormControl(false, [Validators.required]),
      answer4En: new UntypedFormControl('x', [Validators.required]),
      answer4Fr: new UntypedFormControl('', []),
      answer4Ar: new UntypedFormControl('', []),
      answer4Image: new UntypedFormControl('', []),
      answer4isTrue: new UntypedFormControl(true, [Validators.required]),
      fullAnswer: new UntypedFormControl('', []),
      exam: new UntypedFormControl(this.data, [Validators.required]),
      degree: new UntypedFormControl(5 , [Validators.required]),

      questionImage: new UntypedFormControl("", []),
      fullAnswerImage: new UntypedFormControl("", []),

      videoImage: new UntypedFormControl('', []),
      video: new UntypedFormControl('', []),
    });
  }

  creatQuestionForExam() {
    // this.form.patchValue({
    //   exam: this.data
    // })
    const arr = [this.form.get("answer1isTrue").value ,this.form.get("answer2isTrue").value ,this.form.get("answer3isTrue").value ,this.form.get("answer4isTrue").value];
    const count = arr.filter((value) => value).length;
    if(count !== 1){
      this.toastr.warningToastr(this.translate.instant('must detect one answer as true'));
      return;
    }
    if(!this.form.get('answer1isTrue').value && !this.form.get('answer2isTrue').value && !this.form.get('answer3isTrue').value && !this.form.get('answer4isTrue').value){
      this.toastr.warningToastr(this.translate.instant('must detect one answer as true'));
      return;
    }

    this.full.addQuestionForExam(this.form.value).subscribe(addQuestionForExamData => {
      console.log('data', addQuestionForExamData);
      
      this.toastr.successToastr(this.translate.instant('questionSuccessfully'));
      this.dialogRef.close(true);
    }, err => {
      this.toastr.errorToastr(err);
    })
  }

  onReady(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new Adapter(loader, editor.config);
    };
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  
  qISelectFile(event) {
    this.qIFileList = [];
    this.qIList = event.target.files[0];
    this.qIFileList.push(this.qIList);
    this.qIUpload();
  }

  qIUpload() {
    const fd1 = new FormData();
    fd1.append("images", this.qIFileList[0], this.qIFileList[0].name);
    this.full.uploadImages(fd1).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progressQI = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              questionImage: event.body.images[0],
            });
            this.qIUrlList = event.body.images[0];
        }
      },
      (err) => {
        this.qIUrlList = this.translate.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translate.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedQImage() {
    this.form.patchValue({
      questionImage: null,
    });
    this.progressQI = 0;
    this.qIFileList = [];
  }

  fAISelectFile(event) {
    this.fAIFileList = [];
    this.fAIList = event.target.files[0];
    this.fAIFileList.push(this.fAIList);
    this.fAIUpload();
  }

  fAIUpload() {
    const fd2 = new FormData();
    fd2.append("images", this.fAIFileList[0], this.fAIFileList[0].name);
    this.full.uploadImages(fd2).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            this.progressFAI = Math.round((event.loaded / event.total) * 100);
            break;
          case HttpEventType.Response:
            this.form.patchValue({
              fullAnswerImage: event.body.images[0],
            });
            this.fAIUrlList = event.body.images[0];
        }
      },
      (err) => {
        this.fAIUrlList = this.translate.instant("imageNotUploaded");
      }
    );
    this.full.openSnackBar(
      this.translate.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedFAImage() {
    this.form.patchValue({
      fullAnswerImage: null,
    });
    this.progressFAI = 0;
    this.fAIFileList = [];
  }


  // answer-
  aSelectFile(event, index) {
    switch (index) {
      case 1:
        this.a1FileList = [];
        this.a1List = event.target.files[0];
        this.a1FileList.push(this.a1List);
        break;
      case 2:
        this.a2FileList = [];
        this.a2List = event.target.files[0];
        this.a2FileList.push(this.a2List);
        break;
      case 3:
        this.a3FileList = [];
        this.a3List = event.target.files[0];
        this.a3FileList.push(this.a3List);
        break;
      case 4:
        this.a4FileList = [];
        this.a4List = event.target.files[0];
        this.a4FileList.push(this.a4List);
        break;
      default:
        break;
    }
    this.aUpload(index);
  }

  aUpload(index) {
    const fd3 = new FormData();
    // let file = null;
    switch (index) {
      case 1:
        // file = this.a1FileList
        fd3.append("images", this.a1FileList[0], this.a1FileList[0].name);
        break;
      case 2:
        // file = this.a2FileList
        fd3.append("images", this.a2FileList[0], this.a2FileList[0].name);
        break;
      case 3:
        // file = this.a3FileList
        fd3.append("images", this.a3FileList[0], this.a3FileList[0].name);
        break;
      case 4:
        // file = this.a4FileList
        fd3.append("images", this.a4FileList[0], this.a4FileList[0].name);
        break;
      default:
        break;
    }
    // if(!file) return;
    // fd.append("images", file[0], file[0].name);
    this.full.uploadImages(fd3).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            break;
          case HttpEventType.ResponseHeader:
            break;
          case HttpEventType.UploadProgress:
            switch (index) {
              case 1:
                this.progressA1 = Math.round((event.loaded / event.total) * 100);
                break;
                case 2:
                this.progressA2 = Math.round((event.loaded / event.total) * 100);
                break;
                case 3:
                this.progressA3 = Math.round((event.loaded / event.total) * 100);
                break;
                case 4:
                this.progressA4 = Math.round((event.loaded / event.total) * 100);
                break;
              default:
                break;
            }
            break;
          case HttpEventType.Response:
            switch (index) {
              case 1:
                this.form.patchValue({
                  answer1Image: event.body.images[0],
                });
                this.a1UrlList = event.body.images[0];
                break;
              case 2:
                this.form.patchValue({
                  answer2Image: event.body.images[0],
                });
                this.a2UrlList = event.body.images[0];
                break;
              case 3:
                this.form.patchValue({
                  answer3Image: event.body.images[0],
                });
                this.a3UrlList = event.body.images[0];
                break;
              case 4:
                this.form.patchValue({
                  answer4Image: event.body.images[0],
                });
                this.a4UrlList = event.body.images[0];
                break;
              default:
                break;
            }
        }
      },
      (err) => {
        switch (index) {
          case 1:
            this.a1UrlList = this.translate.instant("imageNotUploaded");
            break;
          case 2:
            this.a2UrlList = this.translate.instant("imageNotUploaded");
            break;
          case 3:
            this.a3UrlList = this.translate.instant("imageNotUploaded");
            break;
          case 4:
            this.a4UrlList = this.translate.instant("imageNotUploaded");
            break;
          default:
            break;
        }
      }
    );
    this.full.openSnackBar(
      this.translate.instant("filesUploadedSuccessfully"),
      "أغلق",
      "success"
    );
  }

  deleteUploadedAImage(index) {
    switch (index) {
      case 1:
        this.form.patchValue({
          answer1Image: null,
        });
        this.progressA1 = 0;
        this.a1FileList = [];
        break;
      case 2:
        this.form.patchValue({
          answer2Image: null,
        });
        this.progressA2 = 0;
        this.a2FileList = [];
        break;
      case 3:
        this.form.patchValue({
          answer3Image: null,
        });
        this.progressA3 = 0;
        this.a3FileList = [];
        break;
      case 4:
        this.form.patchValue({
          answer4Image: null,
        });
        this.progressA4 = 0;
        this.a4FileList = [];
        break;
      default:
        break;
    }

  }


  videoSelectFile(event) {
    this.thumbnailFileListV = [];
    this.thumbnailListV = event.target.files[0];
    this.thumbnailFileListV.push(this.thumbnailListV);
    this.videoUpload();
  }

  videoUpload() {
    let fd = new FormData();

    fd.append('my-video', this.thumbnailFileListV[0], this.thumbnailFileListV[0].name);
    this.full.uploadVideos(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progressV = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            video: event.body.videos[0]
          });
          this.thumbnailUrlListV = event.body.videos[0];
      }
    }, err => {
      this.thumbnailUrlListV = this.translate.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translate.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }
  deleteUploadedVideo() {
    this.form.patchValue({
      videoImage: null
    });
    this.progressV = 0;
    this.thumbnailFileListV = [];
    this.form.get('video').setValue('');
    this.form.get('videoImage').setValue('');
  }



  videoImageSelectFile(event) {
    this.videoImageFileList = [];
    this.videoImageList = event.target.files[0];
    this.videoImageFileList.push(this.videoImageList);
    this.videoImageUpload();
  }

  videoImageUpload() {
    let fd = new FormData();

    fd.append('images', this.videoImageFileList[0], this.videoImageFileList[0].name);
    this.full.uploadImages(fd).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          break;
        case HttpEventType.UploadProgress:
          this.progressVideoImage = Math.round(event.loaded / event.total * 100);
          break;
        case HttpEventType.Response:
          this.form.patchValue({
            videoImage: event.body.images[0]
          });
          this.videoImageUrlList = event.body.images[0];
      }
    }, err => {
      this.videoImageUrlList = this.translate.instant('imageNotUploaded');
    });
    this.full.openSnackBar(this.translate.instant('filesUploadedSuccessfully'), 'أغلق', 'success');
  }

  deleteUploadedVideoImage() {
    this.form.patchValue({
      videoImage: null
    });
    this.progressVideoImage = 0;
    this.videoImageFileList = [];
  }
}